<template>
  <div data-test-key="job-listing-page">

<section class="pt-28 lg:pt-44 pb-28 group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 dark:bg-neutral-900 bg-[url('../images/home/page-title.png')] bg-center bg-cover relative" >
    <div class="container mx-auto">
        <div class="grid">
            <div class="col-span-12">
                <div class="text-center text-white">
                    <h3 class="mb-4 text-[26px]">{{ this.jobListing?.Title }}</h3>
                    <div class="page-next">
                        <nav class="inline-block" aria-label="breadcrumb text-center">
                            <ol class="flex flex-wrap justify-center text-sm font-medium uppercase">
                                <li>
                                    <router-link to="/hiring-companies/">Hiring Companies</router-link>
                                </li>
                                <li><i class="bx bxs-chevron-right align-middle px-2.5"></i><a href="javascript:void(0)" v-on:click="clickCompanyPageLink">{{company?.Name}}</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <img src="@/assets/images/about/shape.png" alt="" class="absolute block bg-cover -bottom-0 dark:hidden">
    <img src="@/assets/images/about/shape-dark.png" alt="" class="absolute hidden bg-cover -bottom-0 dark:block">
</section>


<FeedbackNotification style="margin-bottom: 15px;" 
      v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>

<div v-if="jobPageLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif"/>
    </div>
    <div v-else-if="jobListing != null && this.company != null">

<!-- Start grid -->
    <section class="py-16">
        <div class="container mx-auto">
            <div class="grid grid-cols-12 gap-y-10 lg:gap-10">
                <div class="col-span-12 lg:col-span-12">
                    <div class="border rounded-md border-gray-100/30 dark:border-neutral-600/80">

                        <div class="p-6">
                            <div class="grid grid-cols-12">
                                <div class="col-span-12 lg:col-span-8">
                                    <div class="relative">
                                        <h5 class="mb-1 text-gray-900 dark:text-gray-50">{{ this.jobListing.Title }}</h5>
                                        <ul class="flex gap-4 text-gray-500 dark:text-gray-300">
                                            <li>
                                                <!-- <i class="mdi mdi-account"></i> {{ jobListing.ApplicationCount }} Applicants -->
                                                By: {{company.Name}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-span-12 lg:col-span-4">
                                    <div class="flex gap-3 md:justify-end">
                                        <ul class="flex gap-2 mb-0 md:justify-end">
                                            <!-- <li>
                                                <b class="text-gray-900 dark:text-gray-50">Share:</b>
                                            </li> -->
                                            <li>
                                                <ShareNetwork class="p-2.5 rounded bg-violet-500/20 text-violet-500"
                                                    :popup="{width: 400, height: 200}" 
                                                    network="facebook"
                                                    :url=this.pageUrl
                                                    :title=this.jobListing.Title
                                                    :description=this.jobListing.Title
                                                    quote="quote me"
                                                    hashtags="jobs,it">
                                                    <i class="uil uil-facebook-f"></i>
                                                    <!-- <font-awesome-icon title="facebook" icon="fa-brands fa-facebook" /> -->
                                                </ShareNetwork>
                                            </li>
                                            <li>
                                                <ShareNetwork class="p-2.5 rounded bg-violet-500/20 text-violet-500"
                                                    network="twitter"
                                                    :url=this.pageUrl
                                                    :title=this.jobListing.Title
                                                    :description=this.jobListing.Title
                                                    twitter-user="TheJobTap">
                                                    <font-awesome-icon title="twitter" icon="fa-brands fa-twitter" />
                                                </ShareNetwork>
                                            </li>
                                            <li>
                                                <ShareNetwork class="p-2.5 rounded bg-violet-500/20 text-violet-500"
                                                    network="whatsapp"
                                                    :url=this.pageUrl
                                                    :title=this.jobListing.Title
                                                    :description=this.jobListing.Title>
                                                    <font-awesome-icon title="whatsapp" icon="fa-brands fa-whatsapp" />
                                                </ShareNetwork>
                                            </li>
                                            <li>
                                                <ShareNetwork class="p-2.5 rounded p-2.5 rounded bg-green-500/20 text-green-500"
                                                    network="telegram"
                                                    :url=this.pageUrl
                                                    :title=this.jobListing.Title
                                                    :description=this.jobListing.Title>
                                                    <font-awesome-icon title="telegram" icon="fa-brands fa-telegram" />
                                                </ShareNetwork>
                                            </li>
                                            <li>
                                                <ShareNetwork class="p-2.5 rounded bg-violet-500/20 text-violet-500"
                                                    network="reddit"
                                                    :url=this.pageUrl
                                                    :title=this.jobListing.Title
                                                    :description=this.jobListing.Title>
                                                    <font-awesome-icon title="reddit" icon="fa-brands fa-reddit" />
                                                </ShareNetwork>
                                            </li>
                                            <li>
                                                <ShareNetwork class="p-2.5 rounded bg-violet-500/20 text-violet-500"
                                                    network="linkedin"
                                                    :url=this.pageUrl
                                                    :title=this.jobListing.Title
                                                    :description=this.jobListing.Title>
                                                    <font-awesome-icon title="linkedin" icon="fa-brands fa-linkedin" />
                                                </ShareNetwork>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)" v-on:click="emailJobListingForm" class="p-2.5 rounded bg-red-500/20 text-red-500">
                                                    <i class="uil uil-envelope"></i>
                                                </a>
                                            </li>
                                        </ul>

                                </div>
                                <EmailJobListingForm v-if="this.display.emailForm"
                                        :jobListingIdentifier="jobListing.Identifier"/>
                                    </div>
                            </div>
                            <div class="grid grid-cols-12 mt-8 gap-y-3 lg:gap-3">
                                <div class="col-span-12 lg:col-span-3">
                                    <div class="p-4 border rounded border-gray-100/50 dark:border-neutral-600/80">
                                        <p class="mb-1 text-gray-500 dark:text-gray-300 text-13">Attendance</p>
                                        <p class="font-medium text-gray-900 dark:text-gray-50">{{ jobListing.AttendanceRequirement}}</p>
                                    </div>
                                </div>
                                <div class="col-span-12 lg:col-span-3">
                                    <div class="p-4 border rounded border-gray-100/50 dark:border-neutral-600/80">
                                        <p class="mb-1 text-gray-500 dark:text-gray-300 text-13">Location</p>
                                        <p class="font-medium text-gray-900 dark:text-gray-50">{{ jobListing.Location }}</p>
                                    </div>
                                </div>
                                <div class="col-span-12 lg:col-span-3">
                                    <div class="p-4 border rounded border-gray-100/50 dark:border-neutral-600/80">
                                        <p class="mb-1 text-gray-500 dark:text-gray-300 text-13">Position</p>
                                        <p class="font-medium text-gray-900 dark:text-gray-50">{{ jobListing.ContractType }}</p>
                                    </div>
                                </div>
                                <div class="col-span-12 lg:col-span-3">
                                    <div class="p-4 border rounded border-gray-100/50 dark:border-neutral-600/80">
                                        <p class="mb-1 text-gray-500 dark:text-gray-300 text-13">Estimated Salary</p>
                                        <p v-if="jobListing.Salary.From" class="font-medium text-gray-900 dark:text-gray-50">
                                         <span>{{ jobListing.Salary.From.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0}) }}</span> - <span>{{ jobListing.Salary.To.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0}) }}</span> 
                                        </p>
                                        <p v-else class="font-medium text-gray-900 dark:text-gray-50">
                                          ---
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="relative mt-4">
                                <div class="left-7">
                                    <img :src=company.ImageLogoUrl style="height: 55px; width:155px;" alt="" class="rounded-md img-fluid mb-3">
                                </div>

                                    <div style="float:right;">
                                        <!-- Apply button -->
                                        <JobListingApplyButton 
                                            style="float: right;" 
                                            :jobListingIdentifier="jobListing.Identifier"/>
                                    </div>
                            </div>

                            <div class="mt-5">
                                <!-- <p class="text-gray-900 dark:text-gray-50"><b>By: {{company.Name}}</b></p> -->
                                <p class="text-gray-900 dark:text-gray-50"><b>Posted on:</b> {{jobListing.DateCreated}}</p>
                                <p class="text-gray-900 dark:text-gray-50" v-if="jobListing.DateUpdated"><b>Last modified:</b> {{jobListing.DateUpdated}}</p>
                                <p class="text-gray-900 dark:text-gray-50" v-if="jobListing.HoursPerWeek != 0"><b>Hours pw:</b> {{jobListing.HoursPerWeek}}</p>
                                <p class="text-gray-900 dark:text-gray-50" v-if="jobListing.ContractType == 'Contract'"><b>Contract Length (months):</b> {{jobListing.ContractLengthInMonths}}</p>
                            </div> 

                            <div class="mt-5">
                                <h5 class="mb-3 text-gray-900 dark:text-gray-50">Job Description</h5>
                                <div class="mb-12">
                                    <!-- Job Listing: Description  -->
                                    <WysiwygViewer class="text-gray-900 dark:text-gray-50 mb-12" 
                                        v-if="this.jobListing != ''" :viewerText="this.jobListing.Description">
                                    </WysiwygViewer>
                                </div>
                            </div>
                            <div class="">
                                <!-- Apply button -->
                                <JobListingApplyButton
                                    :jobListingIdentifier="jobListing.Identifier"/>
                            </div>
       
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<!-- End grid -->


<LandingJobListingSearchResult 
      v-if="this.jobListing != null"
      :isRemote= this.jobListing.isRemote
      :tags= this.jobListing.CategoryNames>
    </LandingJobListingSearchResult>

</div>

</div>
</template>

<script>
import { companyAPI } from "@/api/company";
import { jobListingAPI } from "@/api/jobListing";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import WysiwygViewer from '@/components/WysiwygViewer.vue'
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import JobListingApplyButton from "@/components/JobListingApplyButton.vue";
import EmailJobListingForm from "@/components/Forms/EmailJobListingForm.vue";
import LandingJobListingSearchResult from "@/components/JobListingSearchResults/LandingJobListingSearchResult.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  components: { WysiwygViewer, FeedbackNotification, JobListingApplyButton, EmailJobListingForm, LandingJobListingSearchResult },
  data() {
    return {
      pageUrl: "",
      company: null,
      jobListing : null,
      jobPageLoading : true,      
      notification : {
        text : "",
        level : "",
      },
      display: {
        emailForm : false
      },
      jobListings : [],
      jobListingTotalCount : 0,
      jobListingsLoading : true,    
    };
  },
  created() {
    this.loadPage();
  },
  mounted() {

  },
  methods: {
    async loadPage() {
      this.getCompanyByName();
      this.pageUrl = window.location.href;
    },
    async clickCompanyPageLink () {
      this.$router.push('/hiring-companies/' + this.company.Name + '/');
    },
    async clickHiringCompaniesPageLink() {
      this.$router.push('/hiring-companies/');
    },
    async clickRelatedJobSearchLink () {
      let querySearch = "/?tags=" + this.jobListing.CategoryNames.map(entry => entry).join('&tags=');
      if (this.jobListing.AttendanceRequirement == "Remote"){
        querySearch = querySearch + "&isRemote=true";
      }  
      querySearch = querySearch + "&sortOrder=BestMatch";

      this.$router.push('/' + querySearch);
    },
    async emailJobListingForm() {
      this.display.emailForm = !this.display.emailForm;
    },
    async getCompanyByName() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyAPI.getByName(this.$route.params.companyname);
          this.company = result;
        })
      );
      if (!result.success) 
      {
        this.jobPageLoading = false;
        this.notification = { text : result.data, level : "error" };        
      } 
      else
      {
        this.getJobListingByIdentifier();
      }
    },
    async getJobListingByIdentifier() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingAPI.getByIdentifier(this.$route.params.identifier);
          this.jobListing = result;
        })
      );
      this.jobPageLoading = false;
      if (!result.success) 
      {
        this.notification = { text : result.data, level : "error" };        
      }
      else
      {
        document.title = this.jobListing.Title + ' by ' + this.company.Name + ' - TheJobTap.com';
      }
    },
  },
};
</script>