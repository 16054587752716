<template>
  <div class="page-content"  data-test-key="terms-and-privacy-page">
    <section class="pt-28 lg:pt-44 pb-28 group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 dark:bg-neutral-900 bg-[url('../images/home/page-title.png')] bg-center bg-cover relative" >
        <div class="container mx-auto">
            <div class="grid">
                <div class="col-span-12">
                    <div class="text-center text-white">
                        <h3 class="mb-4 text-[26px]">Policies</h3>
                        <div class="page-next">
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. 
                                Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <img src="@/assets/images/about/shape.png" alt="" class="absolute block bg-cover -bottom-0 dark:hidden">
    <img src="@/assets/images/about/shape-dark.png" alt="" class="absolute hidden bg-cover -bottom-0 dark:block">
    </section>

<!-- Start Privacy-policy -->
    <section class="py-20">
        <div class="container mx-auto">
            <div class="grid grid-cols-1">
                <div class="relative">
                    <h5 class="mb-4 text-gray-900 dark:text-gray-50">Use for TheJobTap.com</h5>
                    <ul class="pb-2 mb-4 space-y-4 text-gray-500 about-list dark:text-gray-300">
                        <li class="ltr:pl-7 rtl:pr-7">
                            At TheJobTap, accessible at TheJobTap.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Jobcy and how we use
                            If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us through email at <b class="text-red-600">Jobcytechnologypvt.ltd.com</b>
                        </li>
                        <li class="ltr:pl-7 rtl:pr-7">
                            If you have additional questions or require more information about our Privacy Policy
                        </li>
                        <li class="ltr:pl-7 rtl:pr-7">
                            This privacy policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Jobcy. This policy is not applicable to any information collected offline or via channels other than this website.
                        </li>
                        <li class="ltr:pl-7 rtl:pr-7">
                            Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity to our website with regards to the information that they shared and/or collect in Jobcy. This policy is not applicable to any information collected offline or via channels other than this website.
                        </li>
                    </ul>
                </div>
                <div class="relative mt-4">
                    <h5 class="mb-4 text-gray-900 dark:text-gray-50">We use your information to :</h5>
                    <ul class="pb-2 mb-4 space-y-4 text-gray-500 about-list dark:text-gray-300">
                        <li class="ltr:pl-7 rtl:pr-7">
                            Digital Marketing Solutions for Tomorrow
                        </li>
                        <li class="ltr:pl-7 rtl:pr-7">
                            Our Talented & Experienced Marketing Agency
                        </li>
                        <li class="ltr:pl-7 rtl:pr-7">
                            It is said that song composers of the past used texts.
                        </li>
                        <li class="ltr:pl-7 rtl:pr-7">
                            Create your own skin to match your brand
                        </li>
                    </ul>
                </div>
                <div class="relative mt-4">
                    <h5 class="mb-4 text-gray-900 dark:text-gray-50">Privacy and copyright protection :</h5>
                    <ul class="pb-2 mb-4 space-y-4 text-gray-500 about-list dark:text-gray-300">
                        <li class="ltr:pl-7 rtl:pr-7">
                            There is now an <b class="text-red-600">abundance</b> of readable dummy texts. These are usually used when a text is required purely to fill a space. These alternatives to the classic Lorem Ipsum texts are often amusing and tell short, funny or nonsensical stories.
                        </li>
                        <li class="ltr:pl-7 rtl:pr-7">
                            It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
<!-- End Privacy-policy -->

</div>
</template>

<script>
export default{
  data() {
    return {
    };
  },
  mounted() {
    document.title = 'Terms - TheJobTap.com'
  }
};
</script>