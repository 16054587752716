<template>


<div class="page-content" data-test-key="home-page">

<section class="pt-28 lg:pt-44 pb-28 group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 dark:bg-neutral-900 bg-[url('../images/home/page-title.png')] bg-center bg-cover relative" >
    <div class="container mx-auto">
        <div class="grid">
            <div class="col-span-12">
                <div class="text-center text-white">
                    <h3 class="mb-4 text-[26px]">Search Web3 and Crypto jobs</h3>
                    <!-- <img src="https://imagedelivery.net/EWJlSj3VmuhdddwuuB6Q0A/d3717885-7e3a-4baf-4b4f-de0e5993cc00/public" style="height: 80px; display:inline-block;">
                    <p><i><b>empowering the web3 job space</b></i></p>  -->
                    <div class="page-next">
                      <p>Explore <span v-if="!pageLoading">{{this.jobListingResultCount}}</span> jobs from leading web3 companies, find great web3 industry opportunities and boost your web3 career.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <img src="@/assets/images/about/shape.png" alt="" class="absolute block bg-cover -bottom-0 dark:hidden">
    <img src="@/assets/images/about/shape-dark.png" alt="" class="absolute hidden bg-cover -bottom-0 dark:block">
</section>

<section class="py-16" style="padding-bottom: 0px!important;" v-if="pageLoading">
    <div class="container mx-auto">
        <div class="grid grid-cols-12 xl:gap-10 gap-y-12">
            <div class="col-span-12 xl:col-span-12">
              <img class="_imgLoader" src="@/assets/images/loading.gif" style="margin-bottom: 1800px;"/>
            </div>
        </div>
    </div>
</section>

<section class="py-16" style="padding-bottom: 0px!important;" v-if="!pageLoading">
        <div class="container mx-auto">
            <div class="grid grid-cols-12 xl:gap-10 gap-y-12">
                <div class="col-span-12 xl:col-span-12">
                    <div class="job-list-header">
                          <form action="#">
                            <div class="grid grid-cols-12 gap-4">
                              <div class="col-span-12 xl:col-span-6">
                                    <div class="relative filler-job-form">
                                        <!-- <i class="uil uil-location-point"></i> -->
                                        <!-- <input type="search" class="w-full filter-job-input-box dark:text-gray-100" id="exampleFormControlInput1" placeholder="Location, skill.. "> -->
                                        <input 
                                          v-on:keydown.enter.prevent="handleSearchTermInput();" 
                                          v-model="search.searchTerm"
                                          style="float: right; max-width: 330px;"
                                          class="w-full rounded placeholder:text-sm border-gray-100/50 dark:bg-transparent dark:border-gray-800" 
                                          type="text" 
                                          placeholder="Location, company, skill.." />
                                        <a 
                                          class="absolute rounded top-3 ltr:right-4 rtl:left-4" 
                                          @click.stop="handleSearchTermInput()">
                                          <span class="text-gray-500 mdi mdi-magnify"></span>
                                        </a>
                                      </div>
                                </div>
                                <!--end col-->
                                <div class="col-span-12 xl:col-span-6">
                                    <div class="relative filler-job-form  max-lg:text-center max-md:text-center max-sm:text-center">
                                      <div class="checkbox-wrapper-64" >
                                        <label class="switch">
                                          <input type="checkbox" 
                                            v-model="search.isRemote" 
                                            v-on:click="handleIsRemoteClick()">
                                          <span class="slider"></span>
                                        </label>
                                        <div style="display: inline-block; ">
                                          <span class="text-16 text-gray-500 rounded dark:text-gray-300">Remote</span>
                                      </div>
                                      </div> 
                                    </div>
                                </div>
                                <!--end col-->

                                <!-- <div class="col-span-12 xl:col-span-4">
                                    <div class="relative filler-job-form">
                                      <i class="uil uil-location-point"></i>
                                        <select 
                                        class="w-full form-select filter-job-input-box dark:text-gray-100" 
                                        data-trigger name="choices-single-location" 
                                        id="choices-single-location" 
                                        @change="handleSalaryOnChange($event)" v-model="search.minSalary">
                                          <option disabled value=null>Min Salary</option>
                                          <option v-bind:key="salaryBracket" v-for="salaryBracket in salaryBrackets" :value="salaryBracket">{{ salaryBracket.toLocaleString('en-US', { style: 'currency',currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0}) }}+</option>
                                        </select>
                                    </div>
                                </div> -->
                                <!--end col
                                <div class="col-span-12 xl:col-span-3">
                                    <a href="javascript:void(0)" class="w-full text-white border-transparent btn group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 focus:ring focus:ring-custom-500/30"><i class="uil uil-filter"></i> Fliter</a>
                                </div>
                                end col-->
                            </div>
                            <!--end grid-->
                        </form>
                    </div>
<span id="search" ></span>
                    <div class="mt-8" v-if="!pageLoading">
                        <!-- <h6 class="mb-4 text-gray-900 dark:text-gray-50 ">
                          Skills : Group by [tech non-tech]
                          <span class="px-2 py-1 rounded bg-sky-500/20 text-11 text-sky-500 ltr:float-right rtl:float-left">
                            found {{ this.jobListingResultCount }}
                          </span>
                        </h6> -->
                      <!-- Search: Tags -->
                        <ul class="flex flex-wrap gap-2">
                          <li :class="{ active: this.search.tags == '' }" class="border p-[6px] border-gray-100/50 rounded group/joblist dark:border-gray-100/20">
                            <div class="flex items-center">
                              <!-- <div class="px-2 py-1 rounded bg-sky-500/20 text-11 text-sky-500">
                                  {{ this.jobListingResultCount }} 
                              </div>  -->
                              <a href="javascript:void(0)"
                              class="text-gray-900 dark:text-gray-50" 
                                v-on:click="handleCategoryClick(null)"
                                style= "cursor:pointer;">
                                  <h6 class="mb-0 transition-all duration-300 fs-14 group-data-[theme-color=violet]:group-hover/joblist:text-violet-500 group-data-[theme-color=sky]:group-hover/joblist:text-sky-500 group-data-[theme-color=red]:group-hover/joblist:text-red-500 group-data-[theme-color=green]:group-hover/joblist:text-green-500 group-data-[theme-color=pink]:group-hover/joblist:text-pink-500 group-data-[theme-color=blue]:group-hover/joblist:text-blue-500">
                                    All
                                  </h6>
                              </a>
                            </div>
                          </li>
                          <li 
                            :class="{ active: search.tags.includes(jobListingCategory.Name) }"
                            class="border p-[6px] border-gray-100/50 rounded group/joblist dark:border-gray-100/20 " 
                            v-bind:key="jobListingCategory.Name" 
                            v-for="jobListingCategory in jobListingCategories" >
                            <div class="flex items-center" v-if="jobListingCategory.JobListingCount > 0">
                              <!-- <div class="h-8 w-8 text-center group-data-[theme-color=violet]:bg-violet-500/20 group-data-[theme-color=sky]:bg-sky-500/20 group-data-[theme-color=red]:bg-red-500/20 group-data-[theme-color=green]:bg-green-500/20 group-data-[theme-color=pink]:bg-pink-500/20 group-data-[theme-color=blue]:bg-blue-500/20 leading-[2.4] rounded group-data-[theme-color=violet]:text-violet-500 group-data-[theme-color=sky]:text-sky-500 group-data-[theme-color=red]:text-red-500 group-data-[theme-color=green]:text-green-500 group-data-[theme-color=pink]:text-pink-500 group-data-[theme-color=blue]:text-blue-500 text-sm font-medium">
                                        {{jobListingCategory.JobListingCount}}
                                    </div> -->
                                    <a href="javascript:void(0)" 
                                    class="text-gray-900 dark:text-gray-50" 
                                    style = "cursor:pointer;"
                                    v-on:click="handleCategoryClick(jobListingCategory.Name)" >
                                        <h6 class="mb-0 transition-all duration-300 fs-14 group-data-[theme-color=violet]:group-hover/joblist:text-violet-500 group-data-[theme-color=sky]:group-hover/joblist:text-sky-500 group-data-[theme-color=red]:group-hover/joblist:text-red-500 group-data-[theme-color=green]:group-hover/joblist:text-green-500 group-data-[theme-color=pink]:group-hover/joblist:text-pink-500 group-data-[theme-color=blue]:group-hover/joblist:text-blue-500">
                                          {{ jobListingCategory.Name }}
                                        </h6>
                                    </a>
                                </div>
                          </li>
                        </ul>
                    </div>

                    <ul class="flex flex-wrap gap-2 text-gray-700 dark:text-gray-50" v-if="search.location != '' || search.companyName != '' || search.contractType != ''" style="margin-bottom: 15px; margin-top: 15px;">
                      <li style="display:inline-block;" 
                        v-if="this.search.location != null">
                        <span 
                          class="px-1.5 py-0.5 mt-1 text-sm font-medium text-gray-700 rounded border p-[6px] border-gray-100/50 dark:text-gray-50"  
                          style="cursor:pointer;"
                          v-on:click="handleLocationClick(null)"
                          >
                          <i class="uil uil-search"></i> 
                          <a> location: {{ this.search.location }} X</a>
                          </span>
                      </li>                                        
                      <li style="display:inline-block;" 
                      v-if="this.search.companyName != null">
                        <span 
                          class="px-1.5 py-0.5 mt-1 text-sm font-medium text-gray-700 rounded border p-[6px] border-gray-100/50 dark:text-gray-50"  
                          style="cursor:pointer;"
                          v-on:click="handleCompanyNameClick(null)"
                          >
                          <i class="uil uil-search"></i> 
                          <a> company: {{ this.search.companyName }} X</a>
                          </span>
                      </li>                                        
                      <li style="display:inline-block;" 
                      v-if="this.search.contractType != null">
                        <span 
                          class="px-1.5 py-0.5 mt-1 text-sm font-medium text-gray-700 rounded border p-[6px] border-gray-100/50 dark:text-gray-50"  
                          style="cursor:pointer;"
                          v-on:click="handleContractTypeClick(null)"
                          >
                          <i class="uil uil-search"></i> 
                          <a> contractType: {{ this.search.contractType }} X</a>
                          </span>
                      </li>                                        
                  </ul>  

                </div>
            </div>
        </div>
    </section>
<!-- End team -->

                <!-- start job list -->
                <section class="pt-4 py-2 dark:bg-neutral-700 bg-opacity-10" v-if="!pageLoading">
                        <div class="container mx-auto">
                          <div class="grid grid-cols-12 xl:gap-10 gap-y-12">
                            <div class="col-span-12 xl:col-span-12">
                              <div id="search" class="job-list-header">
                                <div class="grid grid-cols-12 gap-4">
                                  <div class="hidden lg:block col-span-12 xl:col-span-4">
                                    <div style="line-height:44.5px;">
                                    <span style=""
                                        class="text-gray-900 text-16 dark:text-gray-50">Loaded {{ this.jobListingShowingCount }} of {{ this.jobListingResultCount }} jobs
                                    </span>
                                  </div> 
                                  </div>
                                  <div class="col-span-12 xl:col-span-4">
                                    <EmailSubscriptionForm 
                                      v-if="!jobListingsLoading"
                                      class="text-center"
                                      :handleResetForm="this.resetEmailForm"
                                      :isRemote=this.search.isRemote
                                      :companyName=null
                                      :tags=this.search.tags
                                      :location=null
                                      :contractType=this.search.contractType
                                      :minSalary=null>
                                      </EmailSubscriptionForm>
                                  </div>
                                  <div class="col-span-12 xl:col-span-4">
                                    <select 
                                        style="float: right; min-width: 250px; margin-right:0px;"
                                        class="hidden lg:block filter-job-input-box dark:text-gray-100" 
                                        v-model="search.sortOrder" @change="handleSortOrderOnChange($event)">
                                      <option v-if="this.search.sortOrder == null" :value="null">{{ this.defaultSortOrder[1] }}</option>
                                      <option v-else :value="this.defaultSortOrder[0]">{{ this.defaultSortOrder[1] }}</option>
                                      <option v-bind:key="sortOrderType" v-for="sortOrderType in sortOrders" :value="sortOrderType[0]">{{ sortOrderType[1] }}</option>
                                    </select> 
                                    <span style=""
                                        class="lg:hidden text-gray-900 text-16 dark:text-gray-50">Showing {{ this.jobListingShowingCount }} of {{ this.jobListingResultCount }} jobs
                                    </span> 
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                                <!-- <div class="grid grid-cols-12">
                                    <div class="col-span-12 lg:col-span-8 lg:col-start-3">
                                        <div class="p-1.5 bg-white dark:bg-neutral-900 shadow-lg shadow-gray-100/30 rounded-lg dark:shadow-neutral-700">
                                            <ul class="items-center text-sm font-medium text-center text-gray-700 nav md:flex">
                                                <li class="w-full">
                                                    <a href="javascript:void(0);" 
                                                        data-tw-toggle="tab" 
                                                        data-tw-target="recent-job" class="inline-block w-full py-[12px] px-[18px] dark:text-gray-50 active" 
                                                        aria-current="page">Recent Jobs</a>
                                                </li>
                                                 <li class="w-full">
                                                    <a href="javascript:void(0);" 
                                                        data-tw-toggle="tab" 
                                                        data-tw-target="part-time-tab" 
                                                        class="inline-block w-full py-[12px] px-[18px] dark:text-gray-50">Part Time</a>
                                                </li>
                                                 <li class="w-full">
                                                    <a href="javascript:void(0);" 
                                                        data-tw-toggle="tab" 
                                                        data-tw-target="full-time-tab" 
                                                        class="inline-block w-full py-[12px] px-[18px] dark:text-gray-50">Full Time</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="block w-full tab-pane" id="recent-job">
                                    <div>
                                        <div class="space-y-8">
                                          <FeedbackNotification style="margin-bottom: 15px;" 
                                            v-if="this.notification.text != ''" 
                                            :feedbackText="this.notification.text" 
                                            :feedbackLevel="this.notification.level"/>

                                            <div v-if="jobListingsLoading">
                                                <!-- <div class="animate-spin inline-block w-8 h-8 border-[3px] border-l-transparent border-violet-500 rounded-full"></div> -->
                                                <img class="_imgLoader" src="@/assets/images/loading.gif" style="margin-bottom: 800px;"/>
                                            </div> 
                                            <div v-else-if="jobListings != ''">

                                              <div v-bind:key="jobListing.Identifier" v-for="jobListing in jobListings" 
                                                class="relative mt-4 overflow-hidden transition-all duration-500 ease-in-out bg-white border rounded-md border-gray-100/50 group/jobs group-data-[theme-color=violet]:hover:border-violet-500 group-data-[theme-color=sky]:hover:border-sky-500 group-data-[theme-color=red]:hover:border-red-500 group-data-[theme-color=green]:hover:border-green-500 group-data-[theme-color=pink]:hover:border-pink-500 group-data-[theme-color=blue]:hover:border-blue-500 hover:-translate-y-2 dark:bg-neutral-900 dark:border-neutral-600 ">
                                                   
                                                <!-- FEATURED STAR-->
                                                <!-- <div class="w-48 absolute -top-[5px] -left-20 -rotate-45 group-data-[theme-color=violet]:bg-violet-500/20 group-data-[theme-color=sky]:bg-sky-500/20 group-data-[theme-color=red]:bg-red-500/20 group-data-[theme-color=green]:bg-green-500/20 group-data-[theme-color=pink]:bg-pink-500/20 group-data-[theme-color=blue]:bg-blue-500/20 group-data-[theme-color=violet]:group-hover/jobs:bg-violet-500 group-data-[theme-color=sky]:group-hover/jobs:bg-sky-500 group-data-[theme-color=red]:group-hover/jobs:bg-red-500 group-data-[theme-color=green]:group-hover/jobs:bg-green-500 group-data-[theme-color=pink]:group-hover/jobs:bg-pink-500 group-data-[theme-color=blue]:group-hover/jobs:bg-blue-500 transition-all duration-500 ease-in-out p-[6px] text-center dark:bg-violet-500/20">
                                                        <a href="javascript:void(0)" class="text-2xl text-white align-middle"><i class="mdi mdi-star"></i></a>
                                                </div>
                                                 -->
                                                    <div class="p-4">
                                                        <div class="grid items-center grid-cols-12">
                                                            <div class="col-span-12 lg:col-span-2">
                                                                <div class="mb-4 text-center mb-md-0">
                                                                  <router-link 
                                                                    :to="'/hiring-companies/' + jobListing.CompanyName + '/' + jobListing.Identifier"> 
                                                                    
                                                                    <img 
                                                                      :src="jobListing.CompanyLogoReference + '/public'" 
                                                                      alt="" 
                                                                      width="100px" 
                                                                      class="mx-auto img-fluid rounded-3">
                                                                  
                                                                  </router-link>

                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-span-12 lg:col-span-1">
                                                                <div class="mb-4 text-center mb-md-0">
                                                                </div>
                                                            </div>  -->
                                                            <!--end col-->
                                                            <div class="col-span-12 lg:col-span-4">
                                                                <div class="mb-2 mb-md-0">
                                                                    <h5 class="mb-1 fs-18">
                                                                        <router-link 
                                                                            :to="'/hiring-companies/' + jobListing.CompanyName + '/' + jobListing.Identifier" 
                                                                            class="text-gray-900 dark:text-gray-50"> {{ jobListing.Title }} </router-link>
                                                                    </h5>
                                                                    <p 
                                                                    :class="{ 
                                                                          'text-violet-500': this.search.companyName != '' && this.search.companyName != null
                                                                          }"
                                                                      style="cursor: pointer;" 
                                                                      v-on:click="handleCompanyNameClick(jobListing.CompanyName)"
                                                                      class="mb-0 text-gray-500 fs-14 dark:text-gray-300">{{ jobListing.CompanyName }}</p>
                                                                </div>
                                                                
                                                            </div>
                                                            <!--end col-->
                                                            <div class="col-span-12 lg:col-span-2">
                                                                <div class="mb-2 lg:flex">
                                                                    <div class="flex-shrink-0" style="float:left;">
                                                                        <i class="mr-1 group-data-[theme-color=violet]:text-violet-500 group-data-[theme-color=sky]:text-sky-500 group-data-[theme-color=red]:text-red-500 group-data-[theme-color=green]:text-green-500 group-data-[theme-color=pink]:text-pink-500 group-data-[theme-color=blue]:text-blue-500 mdi mdi-map-marker"></i>
                                                                    </div>
                                                                    <p 
                                                                    :class="{ 
                                                                          'text-violet-500': this.search.location != '' && this.search.location != null
                                                                          }"
                                                                      style="cursor: pointer;" 
                                                                      v-on:click="handleLocationClick(jobListing.Location)"
                                                                      class="mb-0 text-gray-500 dark:text-gray-300">{{ jobListing.Location }}</p>
                                                                </div>
                                                            </div>
                                                            
                                                            <!--end col-->
                                                            <div class="col-span-12 lg:col-span-2">
                                                                <div v-if="jobListing.Salary.From > 0">
                                                                    <p class="mb-2 text-gray-500 dark:text-gray-300">
                                                                        <span class="group-data-[theme-color=violet]:text-violet-500 group-data-[theme-color=sky]:text-sky-500 group-data-[theme-color=red]:text-red-500 group-data-[theme-color=green]:text-green-500 group-data-[theme-color=pink]:text-pink-500 group-data-[theme-color=blue]:text-blue-500">
                                                                            </span>{{ jobListing.Salary.From.toLocaleString('en-US', { style: 'currency',currency: 'USD', minimumFractionDigits: 0 }) }} - {{ jobListing.Salary.To.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0}) }}</p>
                                                                </div>
                                                            </div>
                                                            <!--end col-->
                                                            <div class="col-span-12 lg:col-span-2">
                                                                <div class="flex flex-wrap gap-1.5">
                                                                    <span 
                                                                    :style="jobListing.AttendanceRequirement == 'Remote' ? 'cursor: pointer;' : ''"
                                                                    v-on:click="jobListing.AttendanceRequirement == 'Remote' ? handleIsRemoteClick() : null;"
                                                                    v-if="jobListing.AttendanceRequirement != 'NotSet'"
                                                                          :class="
                                                                                { 
                                                                                'active': jobListing.AttendanceRequirement == 'Remote' && search.isRemote == true
                                                                                }"
                                                                        class="badge border p-[6px] border-gray-100/50 text-grey-500 text-13 px-2 py-0.5 font-medium rounded dark:text-gray-300">
                                                                        {{ jobListing.AttendanceRequirement }}
                                                                    </span>
                                                                    <span 
                                                                      style="cursor: pointer;" 
                                                                      v-if="jobListing.ContractType != 'NotSet'"
                                                                      v-on:click="handleContractTypeClick(jobListing.ContractType);"
                                                                      :class="
                                                                                { 
                                                                                'active': this.search.contractType != '' && this.search.contractType != null
                                                                                }"
                                                                      class="badge border p-[6px] border-gray-100/50 text-grey-500 text-13 px-2 py-0.5 font-medium rounded dark:text-gray-300">
                                                                        {{ jobListing.ContractType }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <!--end col-->
                                                        </div>
                                                        <!--end row-->
                                                    </div>
                                                    <div class="p-3 bg-gray-50 dark:bg-neutral-700">
                                                        <div class="grid grid-cols-12">
                                                            <div class="col-span-12 lg:col-span-10">
                                                                <div>
                                                                  <ul class="flex flex-wrap gap-2 text-gray-700 dark:text-gray-50">
                                                                            <li><i class="uil uil-tag"></i> Skills :</li>
                                                                            <li style="display:inline-block;" v-bind:key="categoryName" v-for="categoryName in jobListing.CategoryNames" >
                                                                            <span 
                                                                                class="px-1.5 py-0.5 mt-1 text-sm font-medium text-gray-700 rounded border p-[6px] border-gray-100/50 dark:text-gray-50"  
                                                                                style="cursor:pointer;"
                                                                                :class="
                                                                                { 
                                                                                'active': search.tags.includes(categoryName),  
                                                                                }" 
                                                                                  v-on:click="handleCategoryClick(categoryName)"
                                                                                >
                                                                                <a>{{ categoryName }}</a>
                                                                                </span>
                                                                            </li>                                        
                                                                        </ul>   
                                                                </div>
                                                            </div>
                                                            <!--end col-->
                                                            <div class="col-span-12 lg:col-span-2">
                                                                <div class="text-start text-md-end dark:text-gray-50">
                                                                  <a style="cursor: pointer; float:right;" 
                                                                    @click.stop="handleJobListingShowMoreClick(jobListing.Identifier, this)">View 
                                                                    <i class="mdi mdi-chevron-double-down"></i>
                                                                  </a>
                                                                <!-- <div 
                                                                  style="float:right;"
                                                                  class="animate-spin inline-block w-8 h-8 border-[3px] border-l-transparent border-violet-500 rounded-full">
                                                                </div> -->
                                                        
                                                                  <!-- <router-link :to="'/hiring-companies/' + jobListing.CompanyName + '/' + jobListing.Identifier" 
                                                                      data-bs-toggle="modal">View <i class="mdi mdi-chevron-double-right"></i>
                                                                    </router-link> -->
                                                                </div>
                                                            </div>
                                                            <!--end col-->

                                                            <div class="col-span-12" style="padding: 20px; padding-bottom: 50px;" 
                                                              v-if="showMoreJobListing != null && showMoreJobListing.Identifier == jobListing.Identifier">
                                                              <WysiwygViewer class="text-gray-900 dark:text-gray-50" :viewerText="this.showMoreJobListing.Description" />
                                                              <div class="">
                                                                <JobListingApplyButton 
                                                                style="float:right;"
                                                                  :jobListingIdentifier="jobListing.Identifier"/>

                                                                <button 
                                                                  style="float: left; cursor: pointer;" 
                                                                  @click.stop="handleJobListingShowMoreClick(jobListing.Identifier, this)">
                                                                  <i class="mdi mdi-chevron-double-up"></i>
                                                                  minimise
                                                                </button>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <!--end row-->
                                                    </div>
                                              </div>

                                            <div class="mb-12" >
                                              <div class="grid grid-cols-1">
                                                  <div class="text-center">
                                                    <Pagination v-if="this.jobListingResultCount !== 0"
                                                      :totalPages="Math.ceil(jobListingResultCount / pageSize)"
                                                      :perPage="pageSize"
                                                      :currentPage="currentPage"
                                                      @pagechanged="onPageChange" />
                                                  </div>
                                              </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                        </div>
                    </section>
                <!-- end job list         <p><i><b>empowering the web3 job space</b></i></p>  -->
</div>


</template>

<script>
import { jobListingAPI } from "@/api/jobListing";
import { jobListingCategoryAPI } from "@/api/jobListingCategory";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import WysiwygViewer from '@/components/WysiwygViewer.vue'
import Pagination from '@/components/Pagination.vue'
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import JobListingApplyButton from "@/components/JobListingApplyButton.vue";
import EmailSubscriptionForm from "@/components/Forms/EmailSubscriptionForm.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {  
  name: "JobListingSearchView",
  components : {
    EmailSubscriptionForm, WysiwygViewer, FeedbackNotification, JobListingApplyButton, Pagination
  }, 
  data() {
    return {
      notification : {
        text : "",
        level : "",
      },
      emSub: true,
      resetEmailForm: false,
      isApplicationSubmitted : false,
      search: { 
        searchTerm: this.$route.query.searchTerm !== undefined || this.$route.query.searchTerm != '' ? this.$route.query.searchTerm : null,
        isRemote: this.$route.query.isRemote !== undefined ? this.$route.query.isRemote : false,
        companyName: this.$route.query.companyName !== undefined ? this.$route.query.companyName : null,
        tags: this.$route.query.tags !== undefined ? this.$route.query.tags.length == 1 ? [this.$route.query.tags] : this.$route.query.tags : [],
        minSalary: this.$route.query.minSalary !== undefined ? this.$route.query.minSalary : null,
        location: this.$route.query.location !== undefined ? this.$route.query.location : null,
        contractType: this.$route.query.contractType !== undefined ? this.$route.query.contractType : null,
        sortOrder: this.$route.query.sortOrder !== undefined ? this.$route.query.sortOrder : null,
        pageNumber: this.$route.query.pageNumber !== undefined ? this.$route.query.pageNumber : null,
        pageSize: this.$route.query.pageSize !== undefined ? this.$route.query.pageSize : null
      },
      jobListingResultCount : 0,
      jobListingShowingCount : 0,
      jobListings : [],
      pageLoading : true,
      jobListingsLoading : true,
      jobListingCategories : [],
      salaryBrackets : [10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 110000, 120000],
      defaultSortOrder : ["BestMatch","Best Match"],
      sortOrders : [["RecentUpload","Recent Upload"],["HighestSalary","Highest Salary"],["LowestSalary","Lowest Salary"],["MostPopular","Most Popular"]],
      showMoreJobListing : null,
      currentPage: this.$route.query.pageNumber !== undefined ? this.$route.query.pageNumber : 1,
      pageSize: this.$route.query.pageSize !== undefined ? this.$route.query.pageSize : 50,
    };
  },
 created()
 {
    this.getJobListingCategories();
    this.handleJobListingSearch();   
 },
  mounted() {
    document.title = 'Search - TheJobTap.com'
  },
  methods: {
    async createQueryString() {
      const query = Object.assign({}, this.$route.query);
      query.sortOrder = this.search.sortOrder == null ? [] : this.search.sortOrder;
      query.searchTerm = this.search.searchTerm;
      query.tags = this.search.tags;
      query.companyName = this.search.companyName == null ? [] : this.search.companyName;
      query.isRemote = this.search.isRemote == false ? [] : this.search.isRemote;
      query.location = this.search.location == null ? [] : this.search.location;
      query.contractType = this.search.contractType == null ? [] : this.search.contractType;
      query.minSalary = this.search.minSalary == null ? [] : this.search.minSalary;
      query.pageNumber = this.search.pageNumber == null ? [] : this.search.pageNumber;
      query.pageSize = this.search.pageSize == null ? [] : this.search.pageSize;
      await this.$router.push({ query });
    },
    async onPageChange(pageNumber) {
      this.search.pageNumber = pageNumber;
      this.currentPage = pageNumber;
      this.jobListingShowingCount = pageNumber * this.pageSize;
      await this.handleJobListingSearch(false);
    },
    resetPageNumber(){
      this.search.pageNumber = this.search.pageSize;
      this.currentPage = 1;
    },
    async handleSearchTermInput() {
      await this.handleJobListingSearch(true);
    },
    async handleSortOrderOnChange(event){
      this.search.sortOrder = event.target.value;
      await this.handleJobListingSearch(true);
    },
    async handleLocationClick(location) {
      if(this.search.location == location){
        this.search.location = null;
      }
      else{
        this.search.location = location;
      }
      await this.handleJobListingSearch(true);
    },
    async handleCompanyNameClick(companyName) {
      if(this.search.companyName == companyName){
        this.search.companyName = null;
      }
      else{
        this.search.companyName = companyName;
      }
      await this.handleJobListingSearch(true);
    },
    async handleContractTypeClick(contractType) {
      if(this.search.contractType == contractType){
        this.search.contractType = null;
      }
      else{
        this.search.contractType = contractType;
      }
      await this.handleJobListingSearch(true);
    },
    async handleSalaryOnChange(event){
      this.search.minSalary = event.target.value;
      await this.handleJobListingSearch(true);
    },
    async handleIsRemoteClick() {
      this.search.isRemote = !this.search.isRemote;
      await this.handleJobListingSearch(true);
    },
    async handleCategoryClick(categoryName) {
      if (categoryName == null)
      {
         this.search.tags = [];
         await this.handleJobListingSearch(true);
         return;
      }
      if(!Array.isArray(this.search.tags))
      {
         const categoryNames = [];
         categoryNames.push(categoryName)
         this.search.tags = categoryNames;
         await this.handleJobListingSearch(true);
         return;
      }
      const categoryNames = [];
      this.search.tags.forEach(element => {
        if (element != categoryName)
        {
          categoryNames.push(element);
        }
      });
      if (!this.search.tags.includes(categoryName))
      {
        categoryNames.push(categoryName);
      }
      this.search.tags = categoryNames;
      await this.handleJobListingSearch(true);
    },
    async viewJobListingPageClick(companyName, identifier) {
      this.$router.push('/hiring-companies/' + companyName + '/' + identifier + '/');
    },
    async getJobListingCategories() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingCategoryAPI.getCategories(true);
          this.jobListingCategories = result;
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }
    },
    async handleJobListingSearch(resetPageNumber) 
    {
      this.emSub = false;

      if (resetPageNumber)
      {
        this.resetPageNumber();
      }
      this.jobListingsLoading = true;
      this.notification.text = "";
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingAPI.search(this.search.tags, this.search.companyName, 
          this.search.isRemote, this.search.minSalary, this.search.location, this.search.contractType, 
          this.search.searchTerm, this.search.sortOrder, this.pageSize, this.search.pageNumber);
              this.showMoreJobListing = null;
              this.jobListingResultCount = result.ResultCount;
              this.jobListingShowingCount = result.Results.length; 
              this.jobListings = result.Results;
              this.resetEmailForm = true;
              await this.createQueryString();
              this.emSub = true;
              this.pageLoading = false;
        })
      );
      this.jobListingsLoading = false;
      
      if (this.jobListingResultCount === 0)
      {
        this.notification = { text : "No job listing results found for search criteria", level : "notification" };        
      }
      if (!result.success) {
          this.jobListings = null;
          this.notification = { text : result.data, level : "error" };        
      }
    },
    async handleJobListingShowMoreClick(identifier, e) {
      if (this.showMoreJobListing != null && this.showMoreJobListing.Identifier == identifier)
      {
        this.showMoreJobListing = null;
        return;
      }
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingAPI.getByIdentifier(identifier);
          this.showMoreJobListing = result;
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }
    },
  },
};


</script>

