import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  lang: "en",
  dir: "ltr",
  "data-mode": "light",
  class: "scroll-smooths group",
  "data-theme-color": "violet"
}
const _hoisted_2 = { class: "bg-white dark:bg-neutral-800" }
const _hoisted_3 = {
  key: 0,
  style: {"padding":"30px"}
}
const _hoisted_4 = {
  key: 1,
  class: "main-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("html", _hoisted_1, [
    _cache[1] || (_cache[1] = _createStaticVNode("<head><meta charset=\"utf-8\"><meta name=\"viewport\" content=\"width=device-width, initial-scale=1, shrink-to-fit=no\"><meta http-equiv=\"X-UA-Compatible\" content=\"IE=edge\"><link rel=\"shortcut icon\" href=\"./assets/images/favicon.ico\"><link type=\"css\" rel=\"stylesheet\" href=\"./assets/css/icons.css\"><link type=\"css\" rel=\"stylesheet\" href=\"./assets/css/tailwind.css\"></head>", 1)),
    _createElementVNode("body", _hoisted_2, [
      ($data.accessToken != 'pc')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
            _createElementVNode("p", null, null, -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_Navigation),
            _createVNode(_component_router_view),
            _createVNode(_component_Footer)
          ]))
    ])
  ]))
}