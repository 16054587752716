<template >
<!DOCTYPE html>
    <html lang="en" dir="ltr" data-mode="light" class="scroll-smooths group" data-theme-color="violet">
    <head>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />

        <!-- App favicon -->
        <link rel="shortcut icon" href="./assets/images/favicon.ico" />

        <link type="css" rel="stylesheet" href="./assets/css/icons.css" />
        <link type="css" rel="stylesheet" href="./assets/css/tailwind.css" />
        <!-- <link type="css" rel="stylesheet" href="./assets/libs/choices.js/public/assets/styles/choices.min.css"> -->


    </head>
    <body class="bg-white dark:bg-neutral-800">
        <div v-if="accessToken != 'pc'" style="padding: 30px;">
            <p>
            <!-- <input class="formInput" data-test-key="input-access-token" v-model="accessToken" v-on:keyup="setToken" placeholder="Access token" /> -->
            </p>
        </div>
        <div class="main-content" v-else>
            <Navigation />
            <router-view v-cloak/> 
            <Footer />
        </div>
    </body>
  </html>

</template>



<script lang="ts">

import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";

import { loadScript } from "vue-plugin-load-script";

import { companyUserAPI } from "@/api/companyUser";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import store from "./store/vuex";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  name: "App",
  components : {
    Navigation, Footer
  },
  data() {
    return {
      // accessToken : localStorage.getItem('jbpw-auth-token') !== null ? 'pc' : '',
      accessToken : localStorage.getItem('dev-token'),
      hasAccess : false
    };
  },
  async created() {
    if(new URL(location.href).searchParams.get('dev-token') == 'pc')
    {
      localStorage.setItem('dev-token', 'pc');
    }


    // var dev = localStorage.getItem('dev-token');
    // if (dev == 'pc'){
    //   this.hasAccess = true;
    // }

    // if(localStorage.getItem('jbpw-auth-token') != null)
    // {
    //   const result = await log.catchPromise(() =>
    //   pageStatus.observe(async () => {
    //       const result = await companyUserAPI.get();
    //       store.dispatch('companyUserStore', result);
    //     })
    //   );
    //   if (!result.success) {
    //       store.dispatch('companyUserStore', null);
    //       localStorage.removeItem('jbpw-auth-token');
    //   }  
    // }
  },
  mounted(){}
  // methods: {
  //   async setToken() {
  //     localStorage.setItem('dev-token', this.accessToken)
  //   }
  // }
};

loadScript("/assets/js/app.js")
    .then(() => {
      //alert("success")
	  	//console.log("success")
      // loadScript("/assets/libs/@popperjs/core/umd/popper.min.js");
      // loadScript("/assets/js/pages/slibs/simplebar/simplebar.min.js");
      loadScript("/assets/js/pages/switcher.js");
      // loadScript("/assets/js/pages/dropdown&modal.init.js");
	}).catch(() => {
	  //console.log("failed")
    //alert("failed")
    });
  

</script>

<!-- <style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 20px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.active {
    background-color: #59c9e8;
  }

.activeText {
    font-weight: bold;
    color: #59c9e8;
  }
</style>
 -->
