<template>
    <button
      v-on:click="handleApplicationSubmit" 
      class="btn group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 border-transparent text-white hover:-translate-y-1.5">
      Apply Now <i class="uil uil-arrow-right"></i>
    </button>
</template>

<script>
import { jobListingApplicationAPI } from "@/api/jobListingApplication";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  props: ["jobListingIdentifier"],
  components: {
  },
  methods: {

  },
  computed: {
    
  },
  mounted() {
    
  },
  methods: {

  async handleApplicationSubmit() 
    {
      this.isApplicationSubmitted = true;
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          alert("applicantSessionId")
          const result = await jobListingApplicationAPI.submit(this.jobListingIdentifier, "SESSIONID");

          if (result.ApplicationType == "Email")
          {
            window.location.href = "mailto:" + result.ApplicationTypeValue + "?subject=" + result.JobListingTitle + "&body=message%20goes%20here";
          }
          if (result.ApplicationType == "WebsiteLink")
          {
            window.open(result.ApplicationTypeValue, '_blank').focus();
          }
        })
      );
      if (!result.success) {
        //this.notification = { text : result.data, level : "error" };        
        alert(result);
      }
    },
  }
}
</script>