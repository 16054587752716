<template>
  <!-- <ul class="pagination">
   <li class="pagination-item">
      <button type="button" @click="onClickFirstPage" :disabled="isInFirstPage">First</button>
    </li>
    <li v-for="page in pages" :key="page.name" class="pagination-item">
      <button type="button" @click="onClickPage(page.name)" :disabled="page.isDisabled" :class="{ active: isPageActive(page.name) }">
        {{ page.name }}
      </button>
    </li>

    <li class="pagination-item">
      <button type="button" @click="onClickLastPage" :disabled="isInLastPage">Last</button>
    </li> 
  </ul> -->
<!-- v-on:click="clickRelatedJobSearchLink"-->
  <!-- <div class="flex justify-center gap-2 mt-14">
      <button 
        style="min-width: 200px;"
        class="text-gray-500 border-transparent btn bg-violet-500/20 hover:bg-gray-500 hover:text-white hover:ring hover:ring-gray-500/30" 
        >Load more</button>
  </div>  -->

  <ul class="flex justify-center gap-2 mt-14">
                <li @click="onClickFirstPage" :disabled="isInFirstPage"
                 class="w-12 h-12 text-center border rounded-full cursor-default border-gray-100/50 dark:border-zinc-600 dark:border-gray-100/20">
                    <a class="cursor-auto" href="javascript:void(0)" tabindex="-1">
                        <i class="mdi mdi-chevron-double-left text-16 leading-[2.8] dark:text-gray-50"></i>
                    </a>
                </li>
                <li 
                  @click="onClickPage(page.name)" 
                  :disabled="page.isDisabled"
                  v-for="page in pages" 
                  :key="page.name"
                  :class="{
                  'w-12 h-12 text-center text-white border border-transparent rounded-full cursor-pointer group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500'
                    : page.isDisabled == true,
                  'w-12 h-12 text-center text-gray-900 transition-all duration-300 border rounded-full cursor-pointer border-gray-100/50 hover:bg-gray-100/30 focus:bg-gray-100/30 dark:border-gray-100/20 dark:text-gray-50 dark:hover:bg-gray-500/20' 
                    : page.isDisabled == false
                  }">
                    <a class="text-16 leading-[2.8]" href="javascript:void(0)">        
                      {{ page.name }}
                    </a>
                </li>
                <li @click="onClickLastPage" :disabled="isInLastPage" 
                class="w-12 h-12 text-center text-gray-900 transition-all duration-300 border rounded-full cursor-pointer border-gray-100/50 dark:border-zinc-600 hover:bg-gray-100/30 focus:bg-gray-100/30 dark:border-gray-100/20 dark:text-gray-50 dark:hover:bg-gray-500/20">
                    <a href="javascript:void(0)" tabindex="-1">
                        <i class="mdi mdi-chevron-double-right text-16 leading-[2.8]"></i>
                    </a>
                </li>
            </ul>
</template>

<script>
export default {
  props: {
    maxVisibleButtons: { type: Number, required: false, default: 4 },    
    totalPages: { type: Number, required: true },
    perPage: { type: Number, required: true },
    currentPage: { type: Number, required: true }
  },
  computed: {
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }

      // When on the last page
      if (this.currentPage=== this.totalPages) {
        return this.totalPages + 1 - this.maxVisibleButtons;
      }

      // When inbetween
      return this.currentPage - 1;
    },
    pages() {
      const range = [];

      for (
        let i = this.startPage;
        i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i++) 
        {
          if(i > 0){
              range.push({
                name: i,
                isDisabled: i === this.currentPage
          });
        }
      }
      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
  },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1);
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit('pagechanged', page);
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    }
  }
};
</script>

<style lang="scss" scoped>

.pagination {
  list-style-type: none;
}

.pagination-item {
  display: inline-block;
}

.active {
  background-color: #4AAE9B;
  color: #ffffff;
}
</style>