<template>
  <div>
    <button 
    style="cursor: pointer;"
      class="text-white border-transparent btn group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 focus:ring focus:ring-custom-500/30"
      @click.stop="isFormOpened = !isFormOpened"><i class="uil uil-envelope"></i> Create Email Alert</button>
      <div v-if="pageLoading">
        <img class="_imgLoader" src="@/assets/images/loading.gif"/>
      </div>
      <div v-else class="" >
        <div v-if="!isEmailSubscriptionFormSubmitted && isFormOpened">
          <div class="relative z-50 modal" id="modal-id" aria-labelledby="modal-title" role="dialog" aria-modal="true">
              <div class="fixed inset-0 z-50 overflow-hidden">
                  <div class="absolute inset-0 transition-opacity bg-black bg-opacity-50 modal-overlay"></div>
                  <div class="p-4 mx-auto text-center animate-translate sm:max-w-lg">
                      <div class="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl dark:bg-zinc-700">
                          <div class="bg-white dark:bg-zinc-700">
                              <div class="p-5 sm:flex sm:items-finish">
                                  <!-- <div class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full bg-violet-500/200/20 sm:mx-0 sm:h-10 sm:w-10">
                                      <i class="text-xl mdi mdi-delete-outline me-2 group-data-[theme-color=violet]:text-violet-500 group-data-[theme-color=sky]:text-sky-500 group-data-[theme-color=red]:text-red-500 group-data-[theme-color=green]:text-green-500 group-data-[theme-color=pink]:text-pink-500 group-data-[theme-color=blue]:text-blue-500"></i>
                                  </div> -->
                                  <div class="w-full mt-3 text-center sm:mt-0 ltr:sm:ml-4 rtl:sm:mr-4 ltr:sm:text-left rtl:sm:text-right">
                                      <!-- <h3 class="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100" 
                                      id="modal-title">
                                      Get notified of new jobs</h3> -->
                                      <i 
                                      v-on:click="isFormOpened = false"
                                      style="float: right; cursor: pointer;" 
                                      class="text-xl mdi mdi-delete-outline me-2 group-data-[theme-color=violet]:text-violet-500 group-data-[theme-color=sky]:text-sky-500 group-data-[theme-color=red]:text-red-500 group-data-[theme-color=green]:text-green-500 group-data-[theme-color=pink]:text-pink-500 group-data-[theme-color=blue]:text-blue-500"></i>
                                      <div class="mt-2">
                                          <p class="text-sm text-gray-900 dark:text-zinc-100/80">
                                            <b>Get emailed new jobs matching your search</b></p>
                                      </div>
                                      <div class="mt-4">
                                          <hr>
                                        </div>
                                      <div class="mt-4">
                                        <!-- Search: Remote selection -->
                                        <div class="checkbox-wrapper-64">
                                          <label class="switch">
                                          <input type="checkbox" 
                                            v-model="this.search.isRemote">
                                           <span class="slider"></span>
                                          </label>
                                          <span class="relative text-gray-900 text-16 dark:text-gray-50">Remote only</span>
                                        </div> 
                                      </div>
                                          <!-- <div class="mt-4">
                                        <select style="min-width: 180px;" class="w-full filter-job-input-box dark:text-gray-100" v-model="this.search.minSalary">
                                          <option disabled value=null>Min Salary</option>
                                          <option v-bind:key="salaryBracket" v-for="salaryBracket in salaryBrackets" :value="salaryBracket">{{ salaryBracket.toLocaleString('en-US', { style: 'currency',currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0}) }}+</option>
                                        </select>
                                      </div> -->

                                        <!-- Search: Location selection 
                                        <select style="margin-left:20px; min-width: 180px;" class="formInputSmall" v-model="this.search.location">
                                          <option disabled value=null>Location</option>
                                        </select>-->
                                        <div class="mt-4">
                                        <!-- Search: ContractType selection -->
                                          <select style="min-width: 180px;" class="w-full filter-job-input-box dark:text-gray-100" v-model="this.search.contractType">
                                            <option disabled value=null>Contract Type</option>
                                            <option v-bind:key="contractType" v-for="contractType in contractTypes" :value="contractType">{{ contractType }}</option>
                                          </select>
                                        </div>

                                        <div class="mt-4">

                                          <multiselect 
                                            v-model="this.search.tags" 
                                            :options="options" 
                                            :searchable="false" 
                                            :multiple="true" 
                                            :close-on-select="false" 
                                            :clear-on-select="true"              
                                            :preserve-search="false" 
                                            placeholder="Pick skills" 
                                            :select-label="'Tap to select'"
                                            :deselect-label="'Tap to remove'"
                                            label="" 
                                            track-by="" 
                                            :preselect-first="false">
                                            <template #selection="{ values, isOpen }">
                                              <span class="multiselect__single"
                                                    v-if="values.length"
                                                    v-show="!isOpen">
                                                    {{ values.length }} tags selected: 
                                                    <ul class="flex flex-wrap gap-2 ">
                                                      <li 
                                                        class="border p-[6px] border-gray-100/50 rounded group/joblist dark:border-gray-100/20 " 
                                                        v-bind:key="tag" 
                                                        v-for="tag in this.search.tags" >
                                                        <div class="flex items-center">
                                                                <a href="javascript:void(0)" 
                                                                class="text-gray-900 dark:text-gray-50">
                                                                    <h6 class="mb-0 transition-all duration-300 fs-14 group-data-[theme-color=violet]:group-hover/joblist:text-violet-500 group-data-[theme-color=sky]:group-hover/joblist:text-sky-500 group-data-[theme-color=red]:group-hover/joblist:text-red-500 group-data-[theme-color=green]:group-hover/joblist:text-green-500 group-data-[theme-color=pink]:group-hover/joblist:text-pink-500 group-data-[theme-color=blue]:group-hover/joblist:text-blue-500">
                                                                      {{ tag }}
                                                                    </h6>
                                                                </a>
                                                            </div>

                                                      </li>
                                                    </ul>
                                              </span>
                                            </template>
                                          </multiselect>     
                                        </div>

                                        <div class="mt-4">
                                          <hr>
                                        </div>

                                      <div class="mt-4">
                                        <!-- Form -->
                                          <!-- <label for="emailInput" class="text-gray-900 dark:text-gray-50">Email</label> -->
                                          <input data-test-key="input-email-subscription-email" 
                                          type="email"
                                            class="w-full rounded placeholder:text-sm border-gray-100/50 dark:bg-transparent dark:border-gray-800" 
                                            v-model="state.emailSubscriptionForm.emailAddress"
                                            placeholder="Enter your email"
                                            />
                                          <div>
                                            <ValidationMessage v-if="v$.emailSubscriptionForm.emailAddress.$error" :validationMessage ="v$.emailSubscriptionForm.emailAddress.$errors[0].$message"/>
                                          </div>
                                          <select data-test-key="input-ddl-email-subscription-frequency" 
                                            class="mt-4 w-full filter-job-input-box dark:text-gray-100" v-model="state.emailSubscriptionForm.frequencyType">
                                            <option v-bind:key="frequencyType" v-for="frequencyType in emailSubscription.frequencyTypes" :value="frequencyType">{{frequencyType}}</option>
                                          </select>
                                          <button data-test-key="button-email-subscription-submit" 
                                            class="mt-3 w-full btn group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 border-transparent text-white hover:-translate-y-1.5"
                                            v-on:click="handleEmailSubscription">
                                            Subscribe <i class="uil uil-arrow-right"></i>
                                         </button>
                                      <FeedbackNotification v-if="this.notification.text != ''" 
                                        :feedbackText="this.notification.text"
                                        :feedbackLevel="this.notification.level" />
                                      </div>
                                      </div>
                                    </div>
                                    <div class="px-4 py-3 bg-gray-100/50 sm:flex ltr:sm:flex-row-reverse sm:px-6 dark:bg-zinc-800">
                                  <!-- <button type="button" class="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm btn  focus:outline-none group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 group-data-[theme-color=violet]:hover:bg-violet-600 group-data-[theme-color=sky]:hover:bg-sky-600 group-data-[theme-color=red]:hover:bg-red-600 group-data-[theme-color=green]:hover:bg-green-600 group-data-[theme-color=pink]:hover:bg-pink-600 group-data-[theme-color=blue]:hover:bg-blue-600  focus:ring-2 group-data-[theme-color=violet]:ring-violet-500/30 group-data-[theme-color=sky]:ring-sky-500/30 group-data-[theme-color=red]:ring-red-500/30 group-data-[theme-color=green]:ring-green-500/30 sm:ml-3 sm:w-auto sm:text-sm dark:focus:ring-red-500/30">Deactivate</button> -->
                                  <button v-on:click="isFormOpened = false" type="button" class="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm btn dark:text-gray-100 hover:bg-gray-50/50 focus:outline-none focus:ring-2 focus:ring-custom-500/20 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm dark:bg-zinc-700 dark:border-zinc-600 dark:hover:bg-zinc-600 dark:focus:bg-zinc-600 dark:focus:ring-zinc-700 dark:focus:ring-gray-500/20" data-tw-dismiss="modal">Cancel</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

import { jobListingCategoryAPI } from "@/api/jobListingCategory";
import { emailSubscriptionAPI } from "@/api/emailSubscription";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import { reactive, computed } from 'vue' 
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import ValidationMessage from "@/components/ValidationMessage.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import Multiselect from 'vue-multiselect'
import { loadScript } from "vue-plugin-load-script";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  name: "EmailSubscriptionForm",
  components : {
    ValidationMessage, FeedbackNotification, Multiselect
  }, 
  props: ["handleResetForm", "isRemote", "contractType", "tags", "location", "minSalary"],
  setup () {
    const state = reactive({
      emailSubscriptionForm: {
        emailAddress : '',
        frequencyType : 'Daily'
        }
      });
      const rules = computed(() => {
        return {
          emailSubscriptionForm: {
            emailAddress: { required, email },
          }
        }
      })
      const v$ = useVuelidate(rules, state)
      return { state, v$ }
  },
  data() {
    return {
      pageLoading : false, 
      notification : {
        text : "",
        level : "",
      },
      resetForm : this.handleResetForm,
      isFormOpened : false,
      isEmailSubscriptionFormSubmitted : false,
      emailSubscription :{
        frequencyTypes : ['Daily', 'Weekly', 'Monthly']
      },
      search: { 
        searchTerm: this.searchTerm,
        isRemote: this.isRemote == null ? false : this.isRemote,
        tags: this.tags,
        minSalary: this.minSalary,
        location: this.location,
        contractType: this.contractType
      },
      salaryBrackets : [10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 110000, 120000],
      contractTypes : ["FullTime", "PartTime", "Contract", "Internship"],
      jobListingCategories : [],
      value: this.tags,
      options: []
    };
  },
  watch: {
    handleResetForm(e) {
        //alert(this.handleResetForm)
        this.isEmailSubscriptionFormSubmitted = false;
        this.notification.text = '';
        this.notification.level = '';
    }
  },
  mounted() {
      this.getJobListingCategories();
  },
  methods: {
    async handleEmailSubscription() {
      // validate the form
      this.v$.$validate();
      if(!this.v$.$error)
      {
        this.pageLoading = true;
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await emailSubscriptionAPI.create(
              this.state.emailSubscriptionForm.emailAddress, 
              this.tags, 
              null, 
              null,
              '',
              this.isRemote,
              this.state.emailSubscriptionForm.frequencyType,
              this.contractType,
              '');
            if (result.data)
            {
              this.notification = { text : "Subscription Added", level : "success" };
              //this.isEmailSubscriptionFormSubmitted = true;
              //this.resetForm = false;
            }
          })
        );
        this.pageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };        
        }    
      }
    },
    async getJobListingCategories() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingCategoryAPI.getCategories();
          result.forEach((element) => { 
            this.options.push(element.Name);
            //this.options.push({name: element.Name, jobCount: element.JobListingCount});
          })
          // this.tags.forEach((element) => { 
          //   this.value.push({name: element, jobCount: element.JobListingCount});
          // })
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }
    }
  },
};

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
