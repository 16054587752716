<template>
    <FeedbackNotification v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>

  <div v-if="pageLoading">
    <img class="_imgLoader" src="@/assets/images/loading.gif"/>
  </div>
  <div class="" v-else>
    <div v-if="!isContactUsFormSubmitted">
      <div class="grid grid-cols-12 gap-5">
        <div class="col-span-12 lg:col-span-6">
            <div class="mb-3">
                <label for="emailInput" class="text-gray-900 dark:text-gray-50">Email</label>
                <input 
                  class="w-full mt-1 rounded border-gray-100/50 placeholder:text-sm placeholder:text-gray-400 dark:bg-transparent dark:border-gray-800 focus:ring-0 focus:ring-offset-0 dark:text-gray-200"
                  type="email" 
                  name="email" 
                  placeholder="Enter your email"
                  data-test-key="input-contact-email" 
                  v-model="state.contactUsForm.emailAddress"/>
                <ValidationMessage v-if="v$.contactUsForm.emailAddress.$error" :validationMessage ="v$.contactUsForm.emailAddress.$errors[0].$message" />
            </div>
        </div><!--end col-->
        <div class="col-span-12 lg:col-span-6">
            <div class="mb-3">
                <label for="subjectInput" class="text-gray-900 dark:text-gray-50">Subject</label>
                <input 
                type="text" 
                class="w-full mt-1 rounded border-gray-100/50 placeholder:text-sm placeholder:text-gray-400 dark:bg-transparent dark:border-gray-800 focus:ring-0 focus:ring-offset-0 dark:text-gray-200" 
                data-test-key="input-contact-subject" 
                v-model="state.contactUsForm.subject" 
                name="subject" 
                placeholder="Enter your subject" />
                <ValidationMessage v-if="v$.contactUsForm.subject.$error" :validationMessage ="v$.contactUsForm.subject.$errors[0].$message" />
            </div>
        </div><!--end col-->
        <div class="col-span-12">
            <div class="mb-3">
                <label for="meassageInput" class="text-gray-900 dark:text-gray-50">Your Message</label>
                <textarea 
                  class="w-full mt-1 rounded border-gray-100/50 placeholder:text-sm placeholder:text-gray-400 dark:bg-transparent dark:border-gray-800 focus:ring-0 focus:ring-offset-0 dark:text-gray-200" 
                  data-test-key="input-contact-text" 
                  v-model="state.contactUsForm.contactText"
                  placeholder="Enter your message" 
                  name="comments" rows="3"
                ></textarea>
                <ValidationMessage v-if="v$.contactUsForm.contactText.$error" :validationMessage ="v$.contactUsForm.contactText.$errors[0].$message" />
            </div>
        </div><!--end col-->
    </div><!--end row-->
    <div class="text-right">
        <button 
        class="text-white border-transparent btn group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500"
        data-test-key="button-contact-submit" v-on:click="submitContactUsHandler">Send message<i class="uil uil-message ms-1"></i></button>
    </div>

    </div>
 
  </div>
</template>

<script>
import { siteAPI } from "@/api/site";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

import { reactive, computed } from 'vue' 
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import ValidationMessage from "@/components/ValidationMessage.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  components : {
    FeedbackNotification, ValidationMessage
  }, 
  setup () {
    const state = reactive({
      contactUsForm: {
        subject : '',
        emailAddress : '',
        contactText : '',
          }
      });
      const rules = computed(() => {
        return {
          contactUsForm: {
            subject: { required },
            emailAddress: { required, email },
            contactText: { required },
          }
        }
      })
      const v$ = useVuelidate(rules, state)
      return { state, v$ }
  },
  data() {
    return {
      pageLoading : false,
      notification : {
        text : "",
        level : "",
      },
      isContactUsFormSubmitted : false,
    };
  },
  methods: {
    async submitContactUsHandler() {
      // validate the form
      this.v$.$validate();
      if(!this.v$.$error)
      {
        this.pageLoading = true;
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await siteAPI.submitContactUsRequest(
            this.state.contactUsForm.emailAddress, this.state.contactUsForm.contactText);
            this.notification = { text : "Contact form submitted. We will get back to you asap.", level : "success" };  
            this.isContactUsFormSubmitted = true;
            // notify the parent view  
            this.$emit('formsubmitted');
          })
        );
        this.pageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };      
        } 
      }
    }
  },
};
</script>