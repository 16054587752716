<template>
  <div class="page-content" data-test-key="email-subscription-page">

<section class="pt-28 lg:pt-44 pb-28 group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 dark:bg-neutral-900 bg-[url('../images/home/page-title.png')] bg-center bg-cover relative" >
        <div class="container mx-auto">
            <div class="grid">
                <div class="col-span-12">
                    <div class="text-center text-white">
                        <h3 class="mb-4 text-[26px]">Get Notified</h3>
                        <div class="page-next">
                          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. 
                            Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <img src="@/assets/images/about/shape.png" alt="" class="absolute block bg-cover -bottom-0 dark:hidden">
    <img src="@/assets/images/about/shape-dark.png" alt="" class="absolute hidden bg-cover -bottom-0 dark:block">
    </section>
    <section class="py-20">
        <div class="container mx-auto">
            <div class="grid grid-cols-1">
                <div class="relative">
                    <h5 class="mb-4 text-gray-900 dark:text-gray-50">Get Notified</h5>
                    <p class="text-gray-500 about-list dark:text-gray-300">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                      Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>

                <div class="relative mt-4">
                    <h5 class="mb-4 text-gray-900 dark:text-gray-50">Email subscriptions</h5>
                    <ul class="pb-2 mb-4 space-y-4 text-gray-500 about-list dark:text-gray-300">
                        <li class="ltr:pl-7 rtl:pr-7">
                          Easy to manage email subscriptions - Show email sub managment
                        </li>
                        <li class="ltr:pl-7 rtl:pr-7">
                          Can subscribe to any level of the search allowing us to send very relevant jobs
                        </li>
                        <li class="ltr:pl-7 rtl:pr-7">
                          Manage your email subscription and turn it off with ease!
                        </li>
                    </ul>
                </div>
                <div class="relative mt-4">
                    <h5 class="mb-4 text-gray-900 dark:text-gray-50">Channels</h5>
                    <ul class="pb-2 mb-4 space-y-4 text-gray-500 about-list dark:text-gray-300">
                        <li class="ltr:pl-7 rtl:pr-7">
                          Telegram - Join the telegram channel for new job notifications.
                        </li>
                        <li class="ltr:pl-7 rtl:pr-7">
                          Twitter - Join us on Twitter for new job and article alerts. Stay updated with the latest industy news.
                        </li>
                        <li class="ltr:pl-7 rtl:pr-7">
                          LinkedIn - Connect with us on LinkedIn.
                        </li>
                        <li class="ltr:pl-7 rtl:pr-7">
                          Facebook - Join us on Facebook for new job and article alerts. Stay updated with the latest industy news.
                        </li>
                    </ul>
                </div>

                </div>
            </div>
        </div>
    </section>




    </div>
  </template>
  
  <script>

  export default{
    data() {
      return {
      };
    },
    mounted() {
      document.title = 'Get Notified - TheJobTap.com'
    }
  };

  </script>

