<template>
    <div v-if="pageLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif"/>
    </div>
    <div v-else>
      <p>
        <label>Send to email address</label><br/>
        <input 
          class="w-full rounded placeholder:text-sm border-gray-100/50 dark:bg-transparent dark:border-gray-800" 
          v-model="state.emailForm.emailAddress" 
          placeholder="Email Address" />      
        <button 
          class="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm btn dark:text-gray-100 hover:bg-gray-50/50 focus:outline-none focus:ring-2 focus:ring-custom-500/20 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm dark:bg-zinc-700 dark:border-zinc-600 dark:hover:bg-zinc-600 dark:focus:bg-zinc-600 dark:focus:ring-zinc-700 dark:focus:ring-gray-500/20" 
          v-on:click="emailJobListingHandler()">Send</button>
        <br/>
        <ValidationMessage v-if="v$.emailForm.$error" :validationMessage ="v$.emailForm.$errors[0].$message" />
      </p>
      <FeedbackNotification v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>
    </div>
</template>

<script>
import { jobListingAPI } from "@/api/jobListing";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import { reactive, computed } from 'vue' 
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import ValidationMessage from "@/components/ValidationMessage.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  props: ["jobListingIdentifier"],
  components: { ValidationMessage, FeedbackNotification },
  setup () {
    const state = reactive({
      emailForm : {
        emailAddress : ''
        },
      });
      const rules = computed(() => {
        return {
          emailForm: {
            emailAddress : { required, email },
          }
        }})
      const v$ = useVuelidate(rules, state)
      return { state, v$ }
  },
  data() {
    return {
      notification : {
        text : "",
        level : "",
      },
      pageLoading: false, 
    };
  },
  mounted(){

  },
  methods: {
    async emailJobListingHandler() {
      // validate the form
      this.v$.$validate();
      if(!this.v$.$error)
      {
        this.pageLoading = true;
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await jobListingAPI.sendAsEmail(
              this.state.emailForm.emailAddress, 
              this.jobListingIdentifier
              );
              // success   
              if(result.data)
              {
                this.notification.text = "Job listing sent";
                this.notification.level = "success"
                this.isEmailJobListingFormSubmitted = true; 
                // notify the parent view  
                this.$emit('formsubmitted');        
              }    
            })
          );
        this.pageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };
        }
      }
    },
  },
};
</script>