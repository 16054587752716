<template>
    <div class="mt-5" v-if="this.feedbackText != ''" data-test-key="feedback-notification">
        <div class="space-y-4">
            <div 
                class="px-4 py-3 text-gray-500 rounded-md bg-gray-500/10" 
                role="alert"
                :class="{ 
                  'bg-green-500/20': this.feedbackLevel == 'success',
                  'bg-blue-500/20': this.feedbackLevel == 'notification',
                  'bg-yellow-500/20': this.feedbackLevel == 'warning',
                  'bg-red-500/20': this.feedbackLevel == 'error' }"
                  >
                {{ this.notificationText }}
            </div>
         
        </div>
    </div>
</template>

<script>
export default {
  props: ["feedbackText", "feedbackLevel"],
  components: {
  },
  data () {
    return {
      notificationText: this.feedbackText,
      notificationLevel: this.feedbackLevel
    }
  },
  methods: {

  },
  computed: {
    
  },
  watch: {
    feedbackText() {
      this.notificationText = this.feedbackText;
      this.notificationLevel = this.feedbackLevel;
    }
  },
  mounted() {
    
  }
}
</script>

<!-- 
<style scoped>
._divNotification {
    position: relative;
    margin:0 auto;
    margin-top: 10px;
    padding: 1px; 
    text-align: left;
    max-width: 500px; 
    border-left: 5px solid #59c9e8;   
    box-shadow: 1px 7px 10px -5px rgba(0,0,0,0.2);
}
._divNotification p {
    margin-left: 15px;
}

.success {
    border-left: 5px solid #42b983;   
}

.notification {
    border-left: 5px solid #59c9e8;   
}

.warning {
    border-left: 5px solid #eed202;   
}

.error {
    border-left: 5px solid #F84F31;   
}
</style>

-->