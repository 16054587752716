<template>
  <div class="page-content"  data-test-key="article-page">
    <section class="pt-28 lg:pt-44 pb-28 group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 dark:bg-neutral-900 bg-[url('../images/home/page-title.png')] bg-center bg-cover relative" >
        <div class="container mx-auto">
            <div class="grid">
                <div class="col-span-12">
                    <div class="text-center text-white">
                        <h3 class="mb-4 text-[26px]">{{ article?.Title }}</h3>
                        <div class="page-next">
                            <p>{{ article?.Excerpt }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <img src="@/assets/images/about/shape.png" alt="" class="absolute block bg-cover -bottom-0 dark:hidden">
    <img src="@/assets/images/about/shape-dark.png" alt="" class="absolute hidden bg-cover -bottom-0 dark:block">
    </section>
  

<!-- Start grid -->
    <section class="py-16">
    <div v-if="pageLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif"/>
    </div>
    <div v-else>
      <!-- Page: FeedbackNotification -->
      <FeedbackNotification style="margin-bottom: 15px;" 
        v-if="this.notification.text != ''" 
        :feedbackText="this.notification.text" 
        :feedbackLevel="this.notification.level"/>   
      <div v-if="this.article != null" >
        <div class="container mx-auto">
               <div class="grid grid-cols-12 mt-8 md:gap-14">
              
                <div class="col-span-12 lg:col-span-8">
                    <!-- <img :src=article.MainImage alt="" class="rounded-lg"> -->
                    <ul class="flex flex-wrap items-center mt-3 mb-0 text-gray-500">
                        <li>
                            <div class="flex items-center">
                                <div class="shrink-0">
                                    <img :src=article.MainImage alt="" class="w-12 h-12 rounded-full">
                                </div>
                            </div>
                        </li>
                        <li class="ltr:ml-3 rtl:mr-3">
                            <div class="flex items-center">
                                <div class="ltr:ml-2 rtl:mr-2">
                                    <h3 class="mb-0 dark:text-gray-300"> {{ article?.Title  }}</h3>
                                </div>
                            </div>
                        </li>
                    </ul>
                            <ul class="flex gap-2 mb-0 md:justify-end">
                                            <!-- <li>
                                                <b class="text-gray-900 dark:text-gray-50">Share:</b>
                                            </li> -->
                                            <li>
                                                <ShareNetwork class="p-2.5 rounded bg-violet-500/20 text-violet-500"
                                                    :popup="{width: 400, height: 200}" 
                                                    network="facebook"
                                                    :url=this.pageUrl
                                                    :title=this.article.Title
                                                    :description=this.article.Excerpt>
                                                    <font-awesome-icon title="facebook" icon="fa-brands fa-facebook" />
                                                </ShareNetwork>
                                            </li>
                                            <li>
                                                <ShareNetwork class="p-2.5 rounded bg-violet-500/20 text-violet-500"
                                                    network="twitter"
                                                    :url=this.pageUrl
                                                    :title=this.article.Title
                                                    :description=this.article.Excerpt
                                                    twitter-user="TheJobTap">
                                                    <font-awesome-icon title="twitter" icon="fa-brands fa-twitter" />
                                                </ShareNetwork>
                                            </li>
                                            <li>
                                                <ShareNetwork class="p-2.5 rounded bg-violet-500/20 text-violet-500"
                                                    network="whatsapp"
                                                    :url=this.pageUrl
                                                    :title=this.article.Title
                                                    :description=this.article.Excerpt>
                                                    <font-awesome-icon title="whatsapp" icon="fa-brands fa-whatsapp" />
                                                </ShareNetwork>
                                            </li>
                                            <li>
                                                <ShareNetwork class="p-2.5 rounded p-2.5 rounded bg-green-500/20 text-green-500"
                                                    network="telegram"
                                                    :url=this.pageUrl
                                                    :title=this.article.Title
                                                    :description=this.article.Excerpt>
                                                    <font-awesome-icon title="telegram" icon="fa-brands fa-telegram" />
                                                </ShareNetwork>
                                            </li>
                                            <li>
                                                <ShareNetwork class="p-2.5 rounded bg-violet-500/20 text-violet-500"
                                                    network="reddit"
                                                    :url=this.pageUrl
                                                    :title=this.article.Title
                                                    :description=this.article.Excerpt>
                                                    <font-awesome-icon title="reddit" icon="fa-brands fa-reddit" />
                                                </ShareNetwork>
                                            </li>
                                            <li>
                                                <ShareNetwork class="p-2.5 rounded bg-violet-500/20 text-violet-500"
                                                    network="linkedin"
                                                    :url=this.pageUrl
                                                    :title=this.article.Title
                                                    :description=this.article.Excerpt>
                                                    <font-awesome-icon title="linkedin" icon="fa-brands fa-linkedin" />
                                                </ShareNetwork>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)" class="p-2.5 rounded bg-red-500/20 text-red-500">
                                                    <i class="uil uil-envelope"></i>
                                                </a>
                                            </li>
                                        </ul>
                    <div class="mt-4">

        <p  class="text-gray-900 dark:text-gray-50">
            <b class="text-gray-900 dark:text-gray-50">Posted on:</b>
            {{ article.DateCreated }}
        </p>

        <!-- Article: Content -->
        <WysiwygViewer class="text-gray-900 dark:text-gray-50" :viewerText="this.article.Content" />

        <!-- Article: Next and Prevous links -->
        <div style="max-width: 800px; margin: auto; margin-top : 30px; min-height: 80px;">
        
          <button 
            v-if="article.PreviousArticleSlug" 
            v-on:click="clickPostLink(article.PreviousArticleSlug)"
            style="float: left"
            type="button" 
            class="text-gray-500 border-transparent btn bg-violet-500/20 hover:bg-gray-500 hover:text-white hover:ring hover:ring-gray-500/30">
            <!-- prev: {{ article.PreviousArticleTitle }} -->
            <i class="uil uil-arrow-left"></i>
             Previous
        </button>
        <button 
            v-if="article.NextArticleSlug"
            v-on:click="clickPostLink(article.NextArticleSlug)"
            style="float: right"
            type="button" 
            class="text-gray-500 border-transparent btn bg-violet-500/20 hover:bg-gray-500 hover:text-white hover:ring hover:ring-gray-500/30">
            Next
            <i class="uil uil-arrow-right"></i>
            <!-- : {{ article.NextArticleTitle }} -->
        </button>
        </div>
                    </div>
                </div>
                <div class="col-span-12 lg:col-span-4" v-if="this.articleResultCount > 0">
                    <div class="mt-8">
                        <h6 class="mb-2 text-gray-900 text-16 dark:text-gray-50">Related Posts</h6>
                        <div class="w-full h-0.5 rounded-full bg-gray-100/60 dark:bg-gray-500/20">
                            <div class="group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 w-[25%] h-0.5 ltr:rounded-l-full rtl:rounded-r-full" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <ul class="my-4">
                            <li class="flex flex-wrap items-center pb-4 mb-4 gap-y-3 md:gap-y-0" v-bind:key="article.Identifier" v-for="article in articles">
                                <img :src="article.MainImage" alt="" class="w-[86px] object-cover h-16 rounded">
                                <div class="ltr:ml-3 rtl:mr-3 grow">
                                  <a href="#" @click.stop="this.getArticleBySlug(article.Slug)" class="overflow-hidden font-medium text-gray-900 truncate dark:text-gray-50">
                                  {{ article.Title }}</a>
                                </div>
                            </li>
                        </ul>
                        <button 
                            type="button" 
                            v-on:click="clickRelatedArticleSearchLink" 
                            class="text-gray-500 border-transparent btn bg-violet-500/20 hover:bg-gray-500 hover:text-white hover:ring hover:ring-gray-500/30">
                            view more
                        </button>
                    </div>
                    <div class="mt-8">
                        <h6 class="mb-2 text-gray-900 text-16 dark:text-gray-50">Related Job Skills</h6>
                        <div class="w-full h-0.5 rounded-full bg-gray-100/60 dark:bg-gray-500/20">
                            <div class="group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 w-[25%] h-0.5 ltr:rounded-l-full rtl:rounded-r-full" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="flex flex-wrap gap-2 mt-3">
                            <router-link :to="'/web3-jobs/' + categoryName + '/'"
                                v-bind:key="categoryName" 
                                style="margin-right: 8px;"
                                v-for="categoryName in article.Categories"
                                class="px-1.5 py-0.5 mt-1 text-sm font-medium text-green-500 bg-green-500/20 rounded" 
                                >{{ categoryName }}</router-link>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
</div>
    </section>

    <LandingJobListingSearchResult 
      v-if="!this.pageLoading"
      :isRemote= false
      :tags= this.article.Categories>
    </LandingJobListingSearchResult>
</div>
  
</template>

<script>
import { contentAPI } from "@/api/content";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import WysiwygViewer from '@/components/WysiwygViewer.vue'
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import { jobListingAPI } from "@/api/jobListing";
import LandingJobListingSearchResult from "@/components/JobListingSearchResults/LandingJobListingSearchResult.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  name: "Article",
  components : {
    FeedbackNotification, WysiwygViewer, LandingJobListingSearchResult
  }, 
  data() {
    return {
      pageLoading : true,
      article : null,      
      notification : {
        text : "",
        level : "",
      },
      pageUrl : "",
      tags : [],
      jobListings : [],
      jobListingTotalCount : 0,
      articles : null,      
      articleResultCount : 0,
    };
  },
  created(){
    this.getArticle();
    this.pageUrl = window.location.href;
  },
  mounted() {
    document.title = 'Article - TheJobTap.com'
  },
  methods: {
  async clickArticlesLink() {
    this.$router.push('/articles/');
  },
  clickPostLink(slug) {
    this.$router.push('/articles/' + slug + '/');
    this.getArticleBySlug(slug);
  },
  async clickRelatedJobSearchLink () {
    let querySearch = "?tags=" + this.article.Categories.map(entry => entry).join('&tags=');
    querySearch = querySearch + "&sortOrder=BestMatch";
    this.$router.push('/' + querySearch);
  },
  async clickRelatedArticleSearchLink () {
    let querySearch = "?tags=" + this.article.Categories.map(entry => entry).join('&tags=');
    this.$router.push('/articles/' + querySearch);
  },
  async getArticleBySlug(slug) {
    this.pageLoading = true;
    const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await contentAPI.getArticle(slug);
          this.article = result;
          this.tags = this.article.Categories.map(entry => entry);
          document.title = this.article.Title + ' - TheJobTap.com'
        })
      );
      this.pageLoading = false;
      if (!result.success) 
      {
        this.notification = { text : result.data, level : "error" };        
      }
      else {
        this.handleJobListingSearch();
        this.searchArticles();
      }
  },
  async getArticle() {
    const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await contentAPI.getArticle(this.$route.params.slug);
          this.article = result;
          this.tags = this.article.Categories.map(entry => entry);
          document.title = this.article.Title + ' - TheJobTap.com'
        })
      );
      this.pageLoading = false;
      if (!result.success) 
      {
        this.notification = { text : result.data, level : "error" };        
      }
      else {
        this.handleJobListingSearch();
        this.searchArticles();
      }
  },
  async handleJobListingSearch() 
    {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingAPI.search(this.tags, '', false, null, '', '', '', '', 4, 1);
          this.jobListings = result.Results;
          this.jobListingTotalCount = result.ResultCount;
        })
      );
      if (!result.success) {
      }
    },
  async searchArticles() {
    const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await contentAPI.searchArticles(this.tags);
          this.articles = result.Results;
          this.articleResultCount = result.ResultCount;
        })
      );
      if (!result.success) {
      }
  }
}

};
</script>