<template>
       

<nav class="navbar fixed right-0 left-0 top-0 px-5 lg:px-24 transition-all duration-500 ease shadow-lg shadow-gray-200/20 bg-white border-gray-200 dark:bg-neutral-800 z-40 dark:shadow-neutral-900" id="navbar">
    <div class="mx-auto container-fluid">
        <div class="flex flex-wrap items-center justify-between mx-auto">
          <router-link to="/" class="flex items-center">
                <img src="https://imagedelivery.net/EWJlSj3VmuhdddwuuB6Q0A/d3717885-7e3a-4baf-4b4f-de0e5993cc00/public" alt="" class="logo-dark h-[22px] block dark:hidden">
                <img src="https://imagedelivery.net/EWJlSj3VmuhdddwuuB6Q0A/d3717885-7e3a-4baf-4b4f-de0e5993cc00/public" alt="" class="logo-dark h-[22px] hidden dark:block">
              </router-link>
            <button data-collapse-toggle="navbar-collapse" type="button" class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg navbar-toggler group lg:hidden hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
            </button>
            <div class="flex items-center lg:order-2">
                <div>
                    <div class="relative dropdown">
                        <button type="button" class="flex items-center py-5 dropdown-toggle" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                            <span class="fw-medium xl:block dark:text-gray-50">
                                <!-- light-dark mode button -->
                                <!-- onclick="changeMode(event)" -->
                                <a href="javascript: void(0);" id="mode" class="z-40 px-6 py-2 font-normal text-white transition-all duration-300 ease-linear rounded text-14 bg-zinc-800" 
                                    >
                                    <i class="hidden text-xl uil uil-brightness dark:text-white dark:inline-block"></i>
                                    <i class="inline-block text-xl uil uil-moon dark:text-zinc-800 dark:hidden"></i>
                                </a>
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            <div id="navbar-collapse" class="navbar-res items-center justify-between w-full text-sm lg:flex lg:w-auto lg:order-1 group-focus:[.navbar-toggler]:block hidden">
                <ul class="flex flex-col items-start mt-5 mb-10 font-medium lg:mt-0 lg:mb-0 lg:items-center lg:flex-row" id="navigation-menu">
                    <li class="relative dropdown">
                        <button class="py-5 text-gray-800 lg:px-4 dropdown-toggle dark:text-gray-50 lg:h-[70px]" id="home" data-bs-toggle="dropdown">
                            <router-link 
                            :class="{ 
                                    'text-violet-500': this.$route.fullPath == '/'|| this.$route.fullPath == ''
                                    }"
                            class="nav-link" to="/">Search</router-link>
                        </button>
                    </li>
                    <li class="relative dropdown lg:mt-0">
                        <button class="py-5 text-gray-800 lg:px-4 dropdown-toggle dark:text-gray-50 lg:h-[70px]" id="company" data-bs-toggle="dropdown">
                            <router-link 
                            :class="{ 
                                    'text-violet-500': this.$route.fullPath == '/get-notified/'|| this.$route.fullPath == '/get-notified'
                                    }"
                            class="nav-link" to="/get-notified/">Get notified</router-link>
                        </button>
                    </li>
                    <li class="relative dropdown lg:mt-0">
                        <button href="#" class="py-5 text-gray-800 lg:px-4 dropdown-toggle dark:text-gray-50 lg:h-[70px]" id="pages" data-bs-toggle="dropdown">
                            <router-link 
                            :class="{ 
                                    'text-violet-500': this.$route.fullPath == '/hiring-companies/'|| this.$route.fullPath == '/hiring-companies'
                                    }"
                            class="nav-link" to="/hiring-companies/">Hiring companies</router-link>
                        </button>
                    </li>
                    <li class="relative dropdown lg:mt-0">
                        <button href="#" class="py-5 text-gray-800 lg:px-4 dropdown-toggle dark:text-gray-50 lg:h-[70px]" id="pages" data-bs-toggle="dropdown">
                            <router-link 
                            :class="{ 
                                    'text-violet-500': this.$route.fullPath == '/articles/' || this.$route.fullPath == '/articles'
                                    }"
                            class="nav-link" to="/articles/">Articles</router-link>
                        </button>
                    </li>
                    <li class="relative dropdown lg:mt-0">
                        <button href="#" class="py-5 text-gray-800 lg:px-4 dropdown-toggle dark:text-gray-50 lg:h-[70px]" id="blog" data-bs-toggle="dropdown">
                            <router-link 
                            :class="{ 
                                    'text-violet-500': this.$route.fullPath == '/hire-with-us/' || this.$route.fullPath == '/hire-with-us'
                                    }"
                            class="nav-link" to="/hire-with-us/">Hire with us</router-link>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
<!-- 
        <div class="fixed transition-all duration-300 ease-linear top-[27.5rem] switcher" id="style-switcher">
            <div class="w-48 p-4 bg-white shadow-md" >

                <div class="mt-5">
                    <h3 class="mb-2 font-semibold text-gray-900 text-16">Light/dark Layout</h3>
                    <div class="flex justify-center mt-2">
                           light-dark mode button 
                        <a href="javascript: void(0);" id="mode" class="z-40 px-6 py-2 font-normal text-white transition-all duration-300 ease-linear rounded text-14 bg-zinc-800" 
                            onclick="changeMode(event)">
                            <i class="hidden text-xl uil uil-brightness dark:text-white dark:inline-block"></i>
                            <i class="inline-block text-xl uil uil-moon dark:text-zinc-800 dark:hidden"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div> 
       light-dark mode button 
        <a href="javascript: void(0);" onclick="toggleSwitcher()" class="fixed z-40 flex flex-col gap-3 px-4 py-3 font-normal text-white group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 top-96 text-14 ltr:rounded-r rtl:rounded-l">
            <i class="text-xl mdi mdi-cog mdi-spin"></i>
        </a>  -->
<!-- 
  <nav data-test-key="navigation">
    <router-link to="/">Search</router-link> |
    <router-link to="/get-notified/">Get notfied</router-link> |
    <router-link to="/hiring-companies/">Hiring Companies</router-link> |
    <router-link to="/articles/">Articles</router-link> |
    <router-link to="/hire-with-us/">Hire with us</router-link> -->
    <!-- <router-link to="/learn-web3/">Learn Web3</router-link> -->
    <!-- <router-link to="/statistics/">Statistics</router-link> | -->
    <!-- <div v-if="companyUserStore == null" style="margin-top: 10px">
      <span>Company: </span>
      <router-link to="/company-register/">Register</router-link> |
      <router-link to="/company-login/">Login</router-link>      
      <br />
    </div>
    <div v-if="companyUserStore != null">
      <p>Company Account Pages</p>
      <router-link to="/company-dashboard/" data-test-key="nav-company-dashboard">Dashboard</router-link> | 
      <router-link v-if="companyUserStore.IsAdmin && companyUserStore.HasSetCompanyDetails" to="/company-account/manage/" data-test-key="nav-company-manage">Manage Company | </router-link>
      <router-link v-if="companyUserStore.IsAdmin && companyUserStore.HasSetCompanyDetails" to="/company-account/manage-users/" data-test-key="nav-company-manage-members">Manage Members | </router-link>
      <router-link v-if='companyUserStore.IsAdmin && companyUserStore.HasSetCompanyDetails' to="/company-account/subscription/" data-test-key="nav-company-manage-subscription">Subscription | </router-link>
      <router-link v-if='companyUserStore.IsAdmin && companyUserStore.HasSetCompanyDetails' to="/company-account/invoice-history/" data-test-key="nav-company-billing-history">Invoice History | </router-link>
      <a data-test-key="nav-company-logout" v-on:click="handleLogout">Logout</a>
   </div> -->
  <!-- </nav> -->
</template>

<script>

import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import { companyUserAPI } from "@/api/companyUser";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {  
  name: "Navigation",
  components : {
  }, 
  methods: {
    async handleLogout() {
      // remove from local storage
      localStorage.removeItem('jbpw-auth-token');
      // remove from store
      store.dispatch('companyUserStore', null)
      // destroy token
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyUserAPI.logout();
          if(result){
            router.push('/');
          }
        })
      );
      if (!result.success) {

      }  
    },
  },
};
</script>
