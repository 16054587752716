<template>
  <div class="page-content"  data-test-key="manage-email-subscription-page">
    <section class="pt-28 lg:pt-44 pb-28 group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 dark:bg-neutral-900 bg-[url('../images/home/page-title.png')] bg-center bg-cover relative" >
        <div class="container mx-auto">
            <div class="grid">
                <div class="col-span-12">
                    <div class="text-center text-white">
                        <h3 class="mb-4 text-[26px]">Email subscription</h3>
                        <div class="page-next">
                          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <img src="@/assets/images/about/shape.png" alt="" class="absolute block bg-cover -bottom-0 dark:hidden">
    <img src="@/assets/images/about/shape-dark.png" alt="" class="absolute hidden bg-cover -bottom-0 dark:block">
    </section>

    <!-- Start grid -->
    <section class="py-12">
        <div class="container mx-auto  text-center">



    <h3 class="mb-0 dark:text-gray-300">Manage Email Subscription</h3>
    <FeedbackNotification v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>  
    <div v-if="pageLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif"/>
    </div>
    <div v-else >      
      <p><i>We'll package all searches into one email with the most relevent jobs to you..</i></p>
      <div 
        v-bind:key="emailSubscriptionByFrequency" 
        v-for="emailSubscriptionByFrequency in emailSubscriptionsResults" 
        class="grid grid-cols-12 gap-y-10 lg:gap-10 lg:items-center">
        
        <div class="col-span-12 ">

          <h4 class="mt-10">Sent {{ emailSubscriptionByFrequency.Frequency }}</h4>

          <table class="mt-6" style="margin: auto; overflow:auto;">
            <tr>
                  <th style=" min-width:120px; font-weight: bold;"><i class="uil uil-tag"></i> Skill</th>
                  <!-- <td style="min-width:120px; font-weight: bold;">Min Salary</td> -->
                  <!-- <td style="min-width:120px; font-weight: bold;">Location</td> -->
                  <th style="min-width:120px; font-weight: bold;">Remote Only</th>
                  <th style="min-width:120px; font-weight: bold;">Contract Type</th>
                  <th style="min-width:120px; font-weight: bold;">Active</th>
                  <th></th>
                  <th></th>
                </tr>
                <tr v-bind:key="emailSubscription.Identifier" v-for="emailSubscription in emailSubscriptionByFrequency.EmailSubscriptions">
                  <td v-if="emailSubscription.EmailSearchSubscription.CategoryName">
                    <ul class="flex flex-wrap gap-2 text-gray-700 dark:text-gray-50">
                                                                            <li style="display:inline-block;" >
                                                                            <span 
                                                                                class="px-1.5 py-0.5 mt-1 text-sm font-medium text-gray-700 rounded border p-[6px] border-gray-100/50 dark:text-gray-50"  
                                                                                >
                                                                                <a>{{ emailSubscription.EmailSearchSubscription.CategoryName }}</a>
                                                                                </span>
                                                                            </li>                                        
                                                                        </ul>  
                  </td>
                  <!-- <td v-else>---</td>
                  <td v-if="emailSubscription.EmailSearchSubscription.MinSalary">{{ emailSubscription.EmailSearchSubscription.MinSalary.toLocaleString('en-US', { style: 'currency',currency: 'USD', maximumFractionDigits: 0}) }}</td> -->
                  <!-- <td v-else>---</td>
                  <td v-if="emailSubscription.EmailSearchSubscription.Location">{{ emailSubscription.EmailSearchSubscription.Location }}</td> -->
                  <td v-else>---</td>             
                  <td v-if="emailSubscription.EmailSearchSubscription.IsRemote">{{ emailSubscription.EmailSearchSubscription.IsRemote }}</td>
                  <td v-else>---</td>
                  <td v-if="emailSubscription.EmailSearchSubscription.ContractType">{{ emailSubscription.EmailSearchSubscription.ContractType }}</td>
                  <td v-else>---</td>
                  <td>
                    <input v-on:click="handleSetEmailSubscriptionActiveStatusClick(emailSubscription.Identifier)" v-model="emailSubscription.IsActive" type="checkbox" id="checkbox">
                  </td>
                  <td>
                    <button 
                    class="text-gray-500 border-transparent btn bg-violet-500/20 hover:bg-gray-500 hover:text-white hover:ring hover:ring-gray-500/30" 
                    v-on:click="handleRemoveEmailSubscriptionClick(emailSubscription.Identifier)">remove</button>
                    </td>
                </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>

  </div>
</template>

<script>
import { emailSubscriptionAPI } from "@/api/emailSubscription";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  components: { FeedbackNotification },
  data() {
    return {
      emailSubscriptionsResults : [],
      pageLoading : true,      
      notification : {
        text : "",
        level : "",
      },
    };
  },
 created() {
    this.getEmailSubscriptionsResult()
 },
  mounted() {
    document.title = 'Email Subscription - TheJobTap.com';
  },
  methods: {
    async getEmailSubscriptionsResult() {
      this.pageLoading = true;
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await emailSubscriptionAPI.get(this.$route.params.groupidentifier);
          this.emailSubscriptionsResults = result;
        })
      );
      this.pageLoading = false;
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }    
    },
    async handleRemoveEmailSubscriptionClick(identifier) {
      this.pageLoading = true;
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await emailSubscriptionAPI.remove(identifier);
          this.getEmailSubscriptionsResult()        
        })
      );
      this.pageLoading = false;
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };           
      }    
    },
    async handleSetEmailSubscriptionActiveStatusClick(identifier) {
      this.pageLoading = true;
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await emailSubscriptionAPI.setActiveStatus(identifier);
          this.getEmailSubscriptionsResult()
        })
      );
      this.pageLoading = false;
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }    
    },
  },
};
</script>