<template>
                    <!-- start job list -->
                    <section class="py-20 bg-blue-500/20 dark:bg-neutral-700 bg-opacity-10">
                        <div class="container mx-auto">
                            <div class="grid grid-cols-1 gap-5">
                                <div class="mb-5 text-center">
                                    <h3 v-if="this.search.companyName == null" class="mb-3 text-3xl text-gray-900 dark:text-gray-50">Recomended Jobs</h3>
                                    <h3 v-else class="mb-3 text-3xl text-gray-900 dark:text-gray-50">Current Openings at {{ this.search.companyName }}</h3>

                                <!-- Job Listing: Tags -->
                                <div class="mb-2 text-center">
                                    <div v-if="this.search.tags.length > 0" class="shrink-0">
                                        <b class="mb-1 text-gray-500 dark:text-gray-50">based on skills:</b>
                                    </div>
                                    <div class="ltr:ml-2 rtl:mr-2 flex-grow-1">
                                      <router-link 
                                         :to="'/web3-jobs/' + categoryName + '/'"
                                         style="margin-right: 8px;"
                                         v-bind:key="categoryName" 
                                         v-for="categoryName in this.tags"
                                         class="px-1.5 py-0.5 mt-1 text-sm font-medium text-green-500 bg-green-500/20 rounded" 
                                         >{{ categoryName }}</router-link>
                                    </div>
                                </div>
                                    <p class="text-gray-500 whitespace-pre-line dark:text-gray-300">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                                </div>

                                <div class="text-center mb-10">
                                            <!-- Email Subscription Form -->
                                            <EmailSubscriptionForm 
                                            :handleResetForm="this.resetEmailForm"
                                            :isRemote=this.search.isRemote
                                            :companyName=null
                                            :tags= this.search.tags
                                            :location=null
                                            :contractType=null
                                            :minSalary=null>
                                            </EmailSubscriptionForm>
                                        </div>

                            </div>
                            <div class="nav-tabs chart-tabpill">
                                <div class="grid grid-cols-12">
                                    <div class="col-span-12 lg:col-span-8 lg:col-start-3">
                                        <div class="p-1.5 bg-white dark:bg-neutral-900 shadow-lg shadow-gray-100/30 rounded-lg dark:shadow-neutral-700">
                                            <ul class="items-center text-sm font-medium text-center text-gray-700 nav md:flex">
                                                <li class="w-full">
                                                    <a href="javascript:void(0);" 
                                                    @click="this.handleSearchJobListings(false);"
                                                     :class="{
                                                      active: !this.search.isRemote
                                                     }"
                                                        data-tw-toggle="tab" 
                                                        data-tw-target="recent-job" class="inline-block w-full py-[12px] px-[18px] dark:text-gray-50" 
                                                        aria-current="page">Best Match</a>
                                                </li>
                                                 <li class="w-full">
                                                    <a 
                                                    @click="this.handleSearchJobListings(true);"
                                                    :class="{
                                                      active: this.search.isRemote
                                                     }"
                                                    href="javascript:void(0);" 
                                                        data-tw-toggle="tab" 
                                                        data-tw-target="full-time-tab" 
                                                        class="inline-block w-full py-[12px] px-[18px] dark:text-gray-50">Remote Only</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-content">
                                    <div class="block w-full tab-pane" id="recent-job">
                                        <div class="pt-8 ">
                                            <div class="space-y-8">
                                                <div v-if="jobListingsLoading">
                                                    <img class="_imgLoader" src="@/assets/images/loading.gif"/>
                                                </div> 
                                                <div v-else-if="jobListings != ''"  v-bind:key="jobListing.Identifier" v-for="jobListing in jobListings" 
                                                    class="relative mt-4 overflow-hidden transition-all duration-500 ease-in-out bg-white border rounded-md border-gray-100/50 group/jobs group-data-[theme-color=violet]:hover:border-violet-500 group-data-[theme-color=sky]:hover:border-sky-500 group-data-[theme-color=red]:hover:border-red-500 group-data-[theme-color=green]:hover:border-green-500 group-data-[theme-color=pink]:hover:border-pink-500 group-data-[theme-color=blue]:hover:border-blue-500 hover:-translate-y-2 dark:bg-neutral-900 dark:border-neutral-600 ">
                                                        <!-- <div class="w-48 absolute -top-[5px] -left-20 -rotate-45 group-data-[theme-color=violet]:bg-violet-500/20 group-data-[theme-color=sky]:bg-sky-500/20 group-data-[theme-color=red]:bg-red-500/20 group-data-[theme-color=green]:bg-green-500/20 group-data-[theme-color=pink]:bg-pink-500/20 group-data-[theme-color=blue]:bg-blue-500/20 group-data-[theme-color=violet]:group-hover/jobs:bg-violet-500 group-data-[theme-color=sky]:group-hover/jobs:bg-sky-500 group-data-[theme-color=red]:group-hover/jobs:bg-red-500 group-data-[theme-color=green]:group-hover/jobs:bg-green-500 group-data-[theme-color=pink]:group-hover/jobs:bg-pink-500 group-data-[theme-color=blue]:group-hover/jobs:bg-blue-500 transition-all duration-500 ease-in-out p-[6px] text-center dark:bg-violet-500/20">
                                                            <a href="javascript:void(0)" class="text-2xl text-white align-middle"><i class="mdi mdi-star"></i></a>
                                                        </div> -->
                                                        <div class="p-4">
                                                        <div class="grid items-center grid-cols-12">
                                                            <div class="col-span-12 lg:col-span-2">
                                                                <div class="mb-4 text-center mb-md-0">
                                                                  <router-link 
                                                                    :to="'/hiring-companies/' + jobListing.CompanyName + '/' + jobListing.Identifier"> 
                                                                    
                                                                    <img 
                                                                      :src="jobListing.CompanyLogoReference + '/public'" 
                                                                      alt="" 
                                                                      width="100px" 
                                                                      class="mx-auto img-fluid rounded-3">
                                                                  
                                                                  </router-link>

                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-span-12 lg:col-span-1">
                                                                <div class="mb-4 text-center mb-md-0">
                                                                </div>
                                                            </div>  -->
                                                            <!--end col-->
                                                            <div class="col-span-12 lg:col-span-4">
                                                                <div class="mb-2 mb-md-0">
                                                                    <h5 class="mb-1 fs-18">
                                                                        <router-link 
                                                                            :to="'/hiring-companies/' + jobListing.CompanyName + '/' + jobListing.Identifier" 
                                                                            class="text-gray-900 dark:text-gray-50"> {{ jobListing.Title }} </router-link>
                                                                    </h5>
                                                                    <p 
                                                                    :class="{ 
                                                                          'text-violet-500': this.search.companyName != '' && this.search.companyName != null
                                                                          }"
                                                                      v-on:click="handleCompanyNameClick(jobListing.CompanyName)"
                                                                      class="mb-0 text-gray-500 fs-14 dark:text-gray-300">{{ jobListing.CompanyName }}</p>
                                                                </div>
                                                                
                                                            </div>
                                                            <!--end col-->
                                                            <div class="col-span-12 lg:col-span-2">
                                                                <div class="mb-2 lg:flex">
                                                                    <div class="flex-shrink-0" style="float:left;">
                                                                        <i class="mr-1 group-data-[theme-color=violet]:text-violet-500 group-data-[theme-color=sky]:text-sky-500 group-data-[theme-color=red]:text-red-500 group-data-[theme-color=green]:text-green-500 group-data-[theme-color=pink]:text-pink-500 group-data-[theme-color=blue]:text-blue-500 mdi mdi-map-marker"></i>
                                                                    </div>
                                                                    <p 
                                                                    :class="{ 
                                                                          'text-violet-500': this.search.location != '' && this.search.location != null
                                                                          }"
                                                                      v-on:click="handleLocationClick(jobListing.Location)"
                                                                      class="mb-0 text-gray-500 dark:text-gray-300">{{ jobListing.Location }}</p>
                                                                </div>
                                                            </div>
                                                            
                                                            <!--end col-->
                                                            <div class="col-span-12 lg:col-span-2">
                                                                <div v-if="jobListing.Salary.From > 0">
                                                                    <p class="mb-2 text-gray-500 dark:text-gray-300">
                                                                        <span class="group-data-[theme-color=violet]:text-violet-500 group-data-[theme-color=sky]:text-sky-500 group-data-[theme-color=red]:text-red-500 group-data-[theme-color=green]:text-green-500 group-data-[theme-color=pink]:text-pink-500 group-data-[theme-color=blue]:text-blue-500">
                                                                            </span>{{ jobListing.Salary.From.toLocaleString('en-US', { style: 'currency',currency: 'USD', minimumFractionDigits: 0 }) }} - {{ jobListing.Salary.To.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0}) }}</p>
                                                                </div>
                                                            </div>
                                                            <!--end col-->
                                                            <div class="col-span-12 lg:col-span-2">
                                                                <div class="flex flex-wrap gap-1.5">
                                                                    <span 
                                                                    v-if="jobListing.AttendanceRequirement != 'NotSet'"
                                                                          :class="
                                                                                { 
                                                                                'active': jobListing.AttendanceRequirement == 'Remote' && search.isRemote == true
                                                                                }"
                                                                        class="badge border p-[6px] border-gray-100/50 text-grey-500 text-13 px-2 py-0.5 font-medium rounded dark:text-gray-300">
                                                                        {{ jobListing.AttendanceRequirement }}
                                                                    </span>
                                                                    <span 
                                                                      v-if="jobListing.ContractType != 'NotSet'"
                                                                      :class="
                                                                                { 
                                                                                'active': this.search.contractType != '' && this.search.contractType != null
                                                                                }"
                                                                      class="badge border p-[6px] border-gray-100/50 text-grey-500 text-13 px-2 py-0.5 font-medium rounded dark:text-gray-300">
                                                                        {{ jobListing.ContractType }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <!--end col-->
                                                        </div>
                                                        <!--end row-->
                                                    </div>
                                                    <div class="p-3 bg-gray-50 dark:bg-neutral-700">
                                                        <div class="grid grid-cols-12">
                                                            <div class="col-span-12 lg:col-span-10">
                                                                <div>
                                                                  <ul class="flex flex-wrap gap-2 text-gray-700 dark:text-gray-50">
                                                                            <li><i class="uil uil-tag"></i> Skills :</li>
                                                                            <li style="display:inline-block;" v-bind:key="categoryName" v-for="categoryName in jobListing.CategoryNames" >
                                                                            <span 
                                                                                class="px-1.5 py-0.5 mt-1 text-sm font-medium text-gray-700 rounded border p-[6px] border-gray-100/50 dark:text-gray-50"  
                                                                                :class="
                                                                                { 
                                                                                'active': search.tags.includes(categoryName),  
                                                                                }" 
                                                                                >
                                                                                <a>{{ categoryName }}</a>
                                                                                </span>
                                                                            </li>                                        
                                                                        </ul>   
                                                                </div>
                                                            </div>
                                                            <!--end col-->
                                                            <div class="col-span-12 lg:col-span-2">
                                                                <div class="text-start text-md-end dark:text-gray-50">
                                                                  <a style="cursor: pointer; float:right;" 
                                                                    @click.stop="handleJobListingShowMoreClick(jobListing.Identifier, this)">View 
                                                                    <i class="mdi mdi-chevron-double-down"></i>
                                                                  </a>
                                                                <!-- <div 
                                                                  style="float:right;"
                                                                  class="animate-spin inline-block w-8 h-8 border-[3px] border-l-transparent border-violet-500 rounded-full">
                                                                </div> -->
                                                        
                                                                  <!-- <router-link :to="'/hiring-companies/' + jobListing.CompanyName + '/' + jobListing.Identifier" 
                                                                      data-bs-toggle="modal">View <i class="mdi mdi-chevron-double-right"></i>
                                                                    </router-link> -->
                                                                </div>
                                                            </div>
                                                            <!--end col-->

                                                            <div class="col-span-12" style="padding: 20px; padding-bottom: 50px;" 
                                                              v-if="showMoreJobListing != null && showMoreJobListing.Identifier == jobListing.Identifier">
                                                              <WysiwygViewer class="text-gray-900 dark:text-gray-50" :viewerText="this.showMoreJobListing.Description" />
                                                              <div class="">
                                                                <JobListingApplyButton 
                                                                style="float:right;"
                                                                  :jobListingIdentifier="jobListing.Identifier"/>

                                                                <button 
                                                                  style="float: left; cursor: pointer;" 
                                                                  @click.stop="handleJobListingShowMoreClick(jobListing.Identifier, this)">
                                                                  <i class="mdi mdi-chevron-double-up"></i>
                                                                  minimise
                                                                </button>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <!--end row-->
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <FeedbackNotification style="margin-bottom: 15px;" 
                                                        feedbackText="No results found" 
                                                        feedbackLevel="warn"/>   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-8" v-if="!jobListingsLoading && jobListings != ''">
                                <div class="grid grid-cols-1">
                                    <div class="text-center">
                                        <a 
                                        style="cursor: pointer;"
                                        @click.stop="this.searchClickHandle();" 
                                        class="text-white border-transparent group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 btn focus:ring focus:ring-custom-500/20">
                                            View More  
                                            <i class="uil uil-arrow-right ms-1"></i>
                                    </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                <!-- end job list -->

</template>


<script>

import { jobListingAPI } from "@/api/jobListing";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import EmailSubscriptionForm from "@/components/Forms/EmailSubscriptionForm.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import WysiwygViewer from '@/components/WysiwygViewer.vue'
import JobListingApplyButton from "@/components/JobListingApplyButton.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

  export default{
    name: "JobListingSearchCategoryView",
    props: ["isRemote", "tags", "companyName", "pageSize"],
  components : {
    EmailSubscriptionForm, FeedbackNotification, WysiwygViewer, JobListingApplyButton
  }, 
  data() {
    return {
      notification : {
        text : "",
        level : "",
      },
      jobListings : [],
      jobListingTotalCount : 0,
      jobListingsLoading : true,      
      resetEmailForm: false,
      search: {
        tags: this.tags,
        remoteOnly: this.isRemote,
        companyName: this.companyName
      },
      showMoreJobListing : null,
    }
  },
  created()
  {
  },
  mounted() {
    this.handleSearchJobListings(false);
  },
  methods: {
    async viewJobListingPageClick(companyName, identifier) {
      this.$router.push('/hiring-companies/' + companyName + '/' + identifier + '/');
    },
    async handleSearchJobListings(isRemoteOnly) {
      this.jobListingsLoading = true;
      this.search.isRemote = isRemoteOnly;
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingAPI.search(this.search.tags, this.search.companyName, isRemoteOnly, null, '', '', '', '', this.pageSize == null ? 4 : this.pageSize, 1);
          this.jobListings = result.Results;
          this.jobListingTotalCount = result.ResultCount;
        }));
      this.jobListingsLoading = false;
      if (!result.success) 
      {
        this.jobListingsLoading = false;
        this.notification = { text : result.data, level : "error" };        
      }
    },
    async searchClickHandle() {
      var queryString = "";
      if(this.search.tags.length > 0)
      {
        var isFirst = true;
        this.search.tags.forEach(element => {
          if (element != '')
          {
            if(isFirst)
            {
                isFirst = false;
                queryString = queryString + "tags=" + element;
            }
            else
            {
                queryString = queryString + "&tags=" + element;
            }
          }
        });
      } 
      if(this.search.isRemote)
      {
            queryString = queryString + "&isRemote=true";
      }
      if(this.search.companyName != null)
      {
            queryString = queryString + "&companyName=" + this.search.companyName;
      }   
      if(queryString != "")   {
        queryString = "?" + queryString;
      }     
      await this.$router.push('/' + queryString);
    },
    async handleJobListingShowMoreClick(identifier, e) {
      if (this.showMoreJobListing != null && this.showMoreJobListing.Identifier == identifier)
      {
        this.showMoreJobListing = null;
        return;
      }
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingAPI.getByIdentifier(identifier);
          this.showMoreJobListing = result;
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }
    },
  }
};
  </script>