<template>
<div class="page-content" data-test-key="company-page">

<section class="pt-28 lg:pt-44 pb-28 group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 dark:bg-neutral-900 bg-[url('../images/home/page-title.png')] bg-center bg-cover relative" >
    <div class="container mx-auto">
        <div class="grid">
            <div class="col-span-12">
                <div class="text-center text-white">
                    <h3 class="mb-4 text-[26px]">{{ company?.Name }}</h3>
                    <div class="page-next">
                        <p>
                         {{ company?.ActiveJobListingCount }} Web3 job <span v-if="company?.ActiveJobListingCount === 1">opening</span><span v-else>openings</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <img src="@/assets/images/about/shape.png" alt="" class="absolute block bg-cover -bottom-0 dark:hidden">
    <img src="@/assets/images/about/shape-dark.png" alt="" class="absolute hidden bg-cover -bottom-0 dark:block">
</section>


<!-- Start grid -->
    <section class="py-12">
        <div class="container mx-auto">
          <div v-if="companyLoading">
              <img class="_imgLoader" src="@/assets/images/loading.gif"/>
            </div> 
            <div v-else-if="this.company !== null" class="grid grid-cols-12 gap-y-10 lg:gap-10">
              
                <div class="col-span-12 lg:col-span-6">
                    <div class="border rounded border-gray-100/50 dark:border-neutral-600">
                        <div class="p-5 border-b border-gray-100/50 dark:border-neutral-600"  style="height: 200px;">
                            <div class="text-center">
                                <img :src="company.ImageLogoUrl" alt="" class="w-200 h-20 mx-auto">
                                <h6 class="mt-4 mb-0 text-lg text-gray-900 dark:text-gray-50">{{ company.Name }}</h6>
                                <p class="mb-4 text-gray-500 dark:text-gray-300">Since July 2017</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-span-12 lg:col-span-6">
                    <div class="border rounded border-gray-100/50 dark:border-neutral-600">
                        <div class="p-5 border-b border-gray-100/50 dark:border-neutral-600"  style="height: 200px;">
                            <h6 class="mb-5 font-semibold text-gray-900 text-17 dark:text-gray-50">Profile Overview</h6>
                            <ul class="space-y-4">
                                <li>
                                    <div class="flex flex-wrap">
                                        <label class="text-gray-900 w-[118px] font-medium dark:text-gray-50">Employees</label>
                                        <div>
                                            <p class="mb-0 text-gray-500 dark:text-gray-300">{{ company.EmployeeThreshold }}</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="flex flex-wrap">
                                        <label class="text-gray-900 w-[118px] font-medium dark:text-gray-50">Website</label>
                                        <div>
                                            <p class="mb-0 text-gray-500 dark:text-gray-300">{{ company.Website }}</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="flex flex-wrap">
                                        <label class="text-gray-900 w-[118px] font-medium dark:text-gray-50">Established</label>
                                        <div>
                                            <p class="mb-0 text-gray-500 dark:text-gray-300">July 10 2017</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>


                <div class="col-span-12 lg:col-span-12" >
                    <div class="border rounded border-gray-100/50 dark:border-neutral-600">
                        <div class="p-5 border-b border-gray-100/50 dark:border-neutral-600" >
                           
                            <WysiwygViewer class="text-gray-900 dark:text-gray-50" :viewerText="this.company.Description" />

                        </div>
                    </div>
                </div>
               
            </div>
        </div>
        
    </section>

</div>


<LandingJobListingSearchResult 
      v-if="this.jobListing != [] && company != null"
      :isRemote= false
      :tags=[]
      :companyName=company.Name
      :pageSize=20>
    </LandingJobListingSearchResult>

</template>

<script>
import { companyAPI } from "@/api/company";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import WysiwygViewer from '@/components/WysiwygViewer.vue';
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import LandingJobListingSearchResult from "@/components/JobListingSearchResults/LandingJobListingSearchResult.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  components: { WysiwygViewer, FeedbackNotification, LandingJobListingSearchResult },
  data() {
    return {
      company : null,
      companyLoading : true,
      jobListings : [],
      jobListingsLoading : true,      
      notification : {
        text : "",
        level : "",
      },
    };
  },
 created(){
    this.getByName();
 },
  mounted() {

  },
  methods: {
    async viewJobListingPageClick(companyName, identifier) {
      this.$router.push('/hiring-companies/' + companyName + '/' + identifier + '/');
    },
    async viewHiringCompaniesPageClick() {
      this.$router.push('/hiring-companies/');
    },
    async getByName() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyAPI.getByName(this.$route.params.companyname);
          this.company = result;

          document.title = this.company.Name + ' - TheJobTap.com';
          this.handleSearch();
        })
      );

      this.companyLoading = false;

      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      } 
    },
  },
};
</script>