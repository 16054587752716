<template>
  <div class="page-content"  data-test-key="category-page">
    <section class="pt-28 lg:pt-44 pb-28 group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 dark:bg-neutral-900 bg-[url('../images/home/page-title.png')] bg-center bg-cover relative" >
        <div class="container mx-auto">
            <div class="grid">
                <div class="col-span-12">
                    <div class="text-center text-white">
                        <h3 class="mb-4 text-[26px]">Web3 {{ tag?.Name }} jobs</h3>
                        <div class="page-next">
                            <nav class="inline-block" aria-label="breadcrumb text-center">
                            <ol class="flex flex-wrap justify-center text-sm font-medium uppercase">
                                <li><router-link to="/web3-jobs/">Web3 Jobs</router-link></li>
                                <li><i class="bx bxs-chevron-right align-middle px-2.5"></i>{{ tag?.Name }}</li>
                            </ol>
                        </nav>                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img src="@/assets/images/about/shape.png" alt="" class="absolute block bg-cover -bottom-0 dark:hidden">
        <img src="@/assets/images/about/shape-dark.png" alt="" class="absolute hidden bg-cover -bottom-0 dark:block"> 
    </section>


    <section class="py-12">
        <div class="container mx-auto">
          <div v-if="tagLoading">
              <img class="_imgLoader" src="@/assets/images/loading.gif"/>
            </div> 
            <div v-else-if="tagLoading == false && tag == null">
        <p>not found</p>
    </div>
    <div v-else class="grid grid-cols-12 gap-y-10 lg:gap-10 lg:items-center">
     <div class="col-span-12 text-center">
            <ul class="flex flex-wrap items-center mt-3 mb-0 text-gray-500 ">
                        <li v-if="pageContentFound == true">
                            <div class="flex items-center">
                                <div class="shrink-0">
                                    <!-- <img :src=article.MainImage alt="" class="w-12 h-12 rounded-full"> -->
                                    <img class="w-30 h-24 mx-auto" :src=pageContent.MainImage alt="">
                                </div>
                            </div>
                        </li>
                        <li class="ltr:ml-3 rtl:mr-3">
                            <div class="flex items-center">
                                <div class="ltr:ml-2 rtl:mr-2">
                                    <h2 class="mb-0 dark:text-gray-300"> Browse  
                                        <span class="text-yellow-500 fw-bold">{{tag.JobListingCount}}</span> {{ tag?.Name }}
                                        Jobs</h2> 
                                </div>
                            </div>
                        </li>
                    </ul>
      </div>

                <div class="col-span-12 lg:col-span-8">
                    <div class="border rounded border-gray-100/50 dark:border-neutral-600">
                        <div class="p-5 border-b border-gray-100/50 dark:border-neutral-600 "  style="min-height: 250px;">
                            <div v-if="pageContentFound == false" style="margin-bottom: 60px">
                              <p class="text-gray-900 dark:text-gray-50">{{ tag?.Name }} content missing ->  
                                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. 
                                  Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                              </p>
                        </div>
                        <div v-if="pageContentFound == true">
                            <!-- LandingPage: Content -->
                            <WysiwygViewer class="text-gray-900 dark:text-gray-50" :viewerText="this.pageContent.Content" />
                        </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-span-12 lg:col-span-4">
                    <div class="border rounded border-gray-100/50 dark:border-neutral-600">
                        <div class="p-5 border-b border-gray-100/50 dark:border-neutral-600"  style="min-height: 250px;">
                            <div class="text-center">
                             
                            <p class="text-gray-500 dark:text-gray-300 text-center" >Combine your skills and search jobs like this.</p>
                                                <multiselect 
                                                    v-model="this.search.tags" 
                                                    :options="options" 
                                                    :searchable="false" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="true"              
                                                    :preserve-search="false" 
                                                    placeholder="Select skills" 
                                                    :select-label="'Tap to select'"
                                                    :deselect-label="'Tap to remove'"
                                                    label="" 
                                                    track-by="" 
                                                    :preselect-first="false">
                                                    <template #selection="{ values, isOpen }">
                                                        <span class="multiselect__single"
                                                            v-if="values.length"
                                                            v-show="!isOpen">
                                                            {{ values.length }} tags selected: 
                                                            <ul class="flex flex-wrap gap-2">
                                                                <li 
                                                                    class="border p-[6px] border-gray-100/50 rounded group/joblist dark:border-gray-100/20" 
                                                                    v-bind:key="tag" 
                                                                    v-for="tag in this.search.tags" >
                                                                    <div class="flex items-center">
                                                                            <a href="javascript:void(0)" 
                                                                            class="text-gray-900 dark:text-gray-50">
                                                                                <h6 class="mb-0 transition-all duration-300 fs-14 group-data-[theme-color=violet]:group-hover/joblist:text-violet-500 group-data-[theme-color=sky]:group-hover/joblist:text-sky-500 group-data-[theme-color=red]:group-hover/joblist:text-red-500 group-data-[theme-color=green]:group-hover/joblist:text-green-500 group-data-[theme-color=pink]:group-hover/joblist:text-pink-500 group-data-[theme-color=blue]:group-hover/joblist:text-blue-500">
                                                                                {{ tag }}
                                                                                </h6>
                                                                            </a>
                                                                        </div>

                                                                </li>
                                                            </ul>
                                                        </span>
                                                    </template>
                                                </multiselect>
                                                <!-- Search: Remote selection -->
                                                <div class="checkbox-wrapper-64">
                                                  <label class="switch">
                                                    <input type="checkbox" 
                                                      v-on:click="this.search.remoteOnly = !this.search.remoteOnly"
                                                      v-model="this.search.isRemote">
                                                    <span class="slider"></span>
                                                  </label>
                                                  <span class="relative text-gray-900 text-16 dark:text-gray-50">Remote only</span>
                                                </div>
                                                <br>
                                                <button @click.stop="searchClickHandle" class="text-white border-transparent group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 btn hover:-translate-y-2">Search <i class="align-middle uil uil-rocket ms-1"></i></button>
         
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </section>


                <!-- start job list -->
                    <LandingJobListingSearchResult v-if="this.tag != null"
                    :isRemote= this.search.isRemote
                    :tags= Array(this.tag.Name)
                    >
                    </LandingJobListingSearchResult>

                <!-- start blog -->
                    <section class="py-16 bg-gray-50 dark:bg-neutral-700" v-if="articles != ''">
                        <div class="container mx-auto">
                            <div class="grid grid-cols-1 gap-5">
                                <div class="mb-5 text-center">
                                    <h3 class="mb-3 text-3xl text-gray-900 dark:text-gray-50">Articles and Learning resources for {{ tag?.Name }} </h3>
                                    <p class="mb-5 text-gray-500 whitespace-pre-line dark:text-gray-300">Covering everything from how to land a job in the web3 industry, to useful web3 industry resources<br> and the latest web3 news.</p>
                                </div>
                            </div>
                            <div class="grid grid-cols-12 gap-5">
                                <!-- <div v-if="articlesLoading">
                                    <img class="_imgLoader" src="@/assets/images/loading.gif" style="margin-bottom:800px"/>
                                </div> -->
                                <div class="col-span-12 md:col-span-6 lg:col-span-4"
                                    v-bind:key="article" v-for="article in articles" >
                                    <div class="p-2 mt-3 transition-all duration-500 bg-white rounded shadow-lg shadow-gray-100/50 card dark:bg-neutral-800 dark:shadow-neutral-600/20 group/blog">
                                        <div class="p-5">
                                          
                                            <img @click="handleArtlicleClick(article.Slug)"  :src=article.MainImage style="height: 180px; cursor: pointer;"/>

                                            <a href="#" @click="handleArtlicleClick(article.Slug)" class="primary-link" style="cursor: pointer;">
                                                <h5 class="mb-1 text-gray-900 fs-17 dark:text-gray-50">{{ article.Title }}</h5>
                                            </a>
                                            <p class="mb-3 text-gray-500 dark:text-gray-300">{{ article.Excerpt }}</p>
                                            <a href="#" @click="handleArtlicleClick(article.Slug)" 
                                            class="font-medium group-data-[theme-color=violet]:text-violet-500 group-data-[theme-color=sky]:text-sky-500 group-data-[theme-color=red]:text-red-500 group-data-[theme-color=green]:text-green-500 group-data-[theme-color=pink]:text-pink-500 group-data-[theme-color=blue]:text-blue-500">Read more <i class="align-middle mdi mdi-chevron-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                <!-- end blog -->

                <section class="py-16 bg-gray-50 dark:bg-neutral-700" v-if="pageContentFound == true && pageContent.FAQS != null">
                        <div class="container mx-auto">
                            <div class="grid grid-cols-1 gap-5">
                                <div class="mb-5 text-center">
                                    <h3 class="mb-3 text-3xl text-gray-900 dark:text-gray-50">Frequently Asked Questions</h3>
                                    <p class="mb-5 text-gray-500 whitespace-pre-line dark:text-gray-300">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. 
                                        Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                                </div>
                            </div>
                            <div class="grid grid-cols-12 gap-5">
                                <div class="col-span-12 md:col-span-12 lg:col-span-12">
                                    <!-- Frequently Asked Questions -->
                                    <div  style="margin-bottom: 60px">
                                        <div class="accordion">
                                        <details>
                                            <summary>{{ pageContent.FAQS.Question1.Question }}</summary>
                                            <p>{{ pageContent.FAQS.Question1.Answer }}</p>
                                        </details>
                                        <details>
                                            <summary>{{ pageContent.FAQS.Question2.Question }}</summary>
                                            <p>{{ pageContent.FAQS.Question2.Answer }}</p>
                                        </details>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            </div>

</template>
  
  <script>

import Multiselect from 'vue-multiselect'
import { contentAPI } from "@/api/content";
import { jobListingCategoryAPI } from "@/api/jobListingCategory";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import WysiwygViewer from '@/components/WysiwygViewer.vue'
import router from '@/router';
import LandingJobListingSearchResult from "@/components/JobListingSearchResults/LandingJobListingSearchResult.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

  export default{
    name: "JobListingSearchCategoryView",
  components : {
    FeedbackNotification, WysiwygViewer, Multiselect, LandingJobListingSearchResult
  }, 
  data() {
    return {
      notification : {
        text : "",
        level : "",
      },
      tag : null,
      tagLoading : true,
      queryTagName : this.$route.params.category,
      articles : null,      
      articleResultCount : 0,
      articleShowingCount : 0,
      pageContent : null,
      pageContentFound: null,
      options: [],
      search: {
        tags: [this.$route.params.category],
        remoteOnly: false
      }
    }
  },
  created()
  {
      this.getJobListingCategory();
      this.getJobListingCategories();
      this.getJobListingCategoryContent();
      this.handleSearchArticles();
  },
  mounted() {
    document.title = this.$route.params.category + ' jobs - TheJobTap.com'
  },
  methods: {
    async handleArtlicleClick(articleName){
    this.$router.push('/articles/' + articleName + "/");
  },
    async viewJobListingPageClick(companyName, identifier) {
      this.$router.push('/hiring-companies/' + companyName + '/' + identifier + '/');
    },
    async getJobListingCategory() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingCategoryAPI.getCategoryByName(this.queryTagName);
          if (result == null){
            alert("not found");
          }
          else
          {
            this.tag = result;
          }

          this.tagLoading = false;
        })
      );
      if (!result.success) {
        this.tagLoading = false;
        this.notification = { text : result.data, level : "error" };        
      }
    },
    async handleSearchArticles() {
      this.articlesLoading = true;
      const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await contentAPI.searchArticles(this.queryTagName);
            this.articles = result.Results;
            this.articleResultCount = result.ResultCount;
            this.articleShowingCount = result.Results.length; 
            await this.createQueryString();
          })
        );
        this.articlesLoading = false;
        if (!result.success) 
        {
          this.articlesLoading = false;
          this.notification = { text : result.data, level : "error" };        
        }
  },
  async getJobListingCategoryContent() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await contentAPI.getJobListingCategoryContent(this.queryTagName);
          if (result == null){
            this.pageContentFound = false;
          }
          else
          {
            this.pageContentFound = true;
            this.pageContent = result;
          }
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }
    },
    async getJobListingCategories() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingCategoryAPI.getCategories();
          result.forEach((element) => { 
            this.options.push(element.Name);
          })
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }
    },
    async searchClickHandle() {
      var queryString = "";
      if(this.search.tags.length > 0)
      {
        var isFirst = true;
        this.search.tags.forEach(element => {
          if (element != '')
          {
            if(isFirst)
            {
                isFirst = false;
                queryString = queryString + "tags=" + element;
            }
            else
            {
                queryString = queryString + "&tags=" + element;
            }
          }
        });
      } 
      if(this.search.isRemote)
      {
            queryString = queryString + "&isRemote=true";
      }
  
      if(queryString != "")   {
        queryString = "?" + queryString;
      }     
      await this.$router.push('/' + queryString);
    },
  }
};
  </script>

  <style lang="scss">


.accordion {
  margin: auto;
	max-width: 500px;
	width: calc(100% - 20px);
}
details {
	background-color: #fff;
	margin-bottom: 10px;
	// filter: drop-shadow(5px 5px 0px #59c9e8);
}
// details:hover {
// 	 filter: drop-shadow(5px 5px 4px #0A0A0A);
// }
details > summary {
	color: #2c3e50;
	padding: 5px 10px;
	font-size: 1.3em;
	cursor: pointer;
}
details > p {
	padding: 5px 10px 10px 20px;
	color: #2c3e50;
	font-size: 1.1em;
}
</style>