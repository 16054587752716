<template>
<div class="page-content">

<section class="pt-28 lg:pt-44 pb-28 group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 dark:bg-neutral-900 bg-[url('../images/home/page-title.png')] bg-center bg-cover relative" >
    <div class="container mx-auto">
        <div class="grid">
            <div class="col-span-12">
                <div class="text-center text-white">
                    <h3 class="mb-4 text-[26px]">Contact us</h3>
                    <div class="page-next">
                      <p>If you've got a question, a comment, a proposal or anything else, we'd love to hear from you!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <img src="@/assets/images/about/shape.png" alt="" class="absolute block bg-cover -bottom-0 dark:hidden">
    <img src="@/assets/images/about/shape-dark.png" alt="" class="absolute hidden bg-cover -bottom-0 dark:block">
</section>

<!-- Start grid -->
    <section class="pt-16 py-20">
        <div class="container mx-auto">
            <div class="grid items-center grid-cols-12 mt-5 lg:gap-8 gap-y-8">
                <div class="col-span-12 lg:col-span-6">
                    <div class="mt-4  ">
                        <h3 class="mb-2 text-3xl text-gray-900 dark:text-white mb-4">Get in touch</h3>
                        <p class="text-gray-500 dark:text-gray-300 mb-4">You can get in touch by filling out the form below or contacting us at hello@thejobtap.com</p>
                        <ContactUsForm class="contact-form" />
                    </div>
                </div><!--end col-->
                <div class="col-span-12 lg:col-span-5 lg:ml-20">
                    <div class="text-center">
                        <img src="@/assets/images/contact.png" alt="" class="img-fluid">
                    </div>
                    <div class="pt-3 mt-4">
                        <!-- <div class="flex items-center mt-2 text-gray-500">
                            <div class="group-data-[theme-color=violet]:text-violet-500 group-data-[theme-color=sky]:text-sky-500 group-data-[theme-color=red]:text-red-500 group-data-[theme-color=green]:text-green-500 group-data-[theme-color=pink]:text-pink-500 group-data-[theme-color=blue]:text-blue-500 shrink-0 text-22">
                                <i class="uil uil-map-marker"></i>
                            </div>
                            <div class="ltr:ml-2 rtl:mr-2 grow-1">
                                <p class="mb-0 dark:text-gray-300">2453 Clinton StreetLittle Rock, California, USA</p>
                            </div>
                        </div> -->
                        <div class="flex items-center mt-2 text-gray-500">
                            <div class="group-data-[theme-color=violet]:text-violet-500 group-data-[theme-color=sky]:text-sky-500 group-data-[theme-color=red]:text-red-500 group-data-[theme-color=green]:text-green-500 group-data-[theme-color=pink]:text-pink-500 group-data-[theme-color=blue]:text-blue-500 shrink-0 text-22">
                                <i class="uil uil-envelope"></i>
                            </div>
                            <div class="ltr:ml-2 rtl:mr-2 grow-1">
                                <p class="mb-0 dark:text-gray-300">hello@thejobtap.com</p>
                            </div>
                        </div>
                        <!-- <div class="flex items-center mt-2 text-gray-500">
                            <div class="group-data-[theme-color=violet]:text-violet-500 group-data-[theme-color=sky]:text-sky-500 group-data-[theme-color=red]:text-red-500 group-data-[theme-color=green]:text-green-500 group-data-[theme-color=pink]:text-pink-500 group-data-[theme-color=blue]:text-blue-500 shrink-0 text-22">
                                <i class="uil uil-phone-alt"></i>
                            </div>
                            <div class="ltr:ml-2 rtl:mr-2 grow-1">
                                <p class="mb-0 dark:text-gray-300">(+245) 223 1245</p>
                            </div>
                        </div> -->
                    </div>
                </div>
                <!--end col-->
            </div>
        </div>
    </section>
  </div>
</template>

<script>

import ContactUsForm from "@/components/Forms/ContactUsForm.vue";

export default {  
  name: "ContactUsView",
  components : {
    ContactUsForm
  }, 
  mounted() {
    document.title = 'Contact - TheJobTap.com'
  }
};
</script>