<template>
<footer class="footer">
    <section class="py-12 bg-zinc-800 dark:bg-neutral-900">
        <div class="container mx-auto">
            <div class="grid grid-cols-12 lg:gap-10">
                <div class="col-span-12 xl:col-span-4">
                    <div class="mr-12">
                        <h4 class="text-white mb-6 text-[23px]">TheJobTap</h4>
                        <p class="text-white/50 dark:text-gray-300">
                            Advertising the latest jobs from leading web3 companies, find great web3 industry opportunities and boost your web3 career.
                        </p>
                        <p class="mt-3 text-white dark:text-gray-50">Follow Us on:</p>
                        <div class="mt-5">
                            <ul class="flex gap-3">
                                <li class="w-8 h-8 leading-loose text-center text-gray-200 transition-all duration-300 border rounded-full cursor-pointer border-gray-200/50 hover:text-gray-50 group-data-[theme-color=violet]:hover:bg-violet-500 group-data-[theme-color=sky]:hover:bg-sky-500 group-data-[theme-color=red]:hover:bg-red-500 group-data-[theme-color=green]:hover:bg-green-500 group-data-[theme-color=pink]:hover:bg-pink-500 group-data-[theme-color=blue]:hover:bg-blue-500 hover:border-transparent">
                                    <a href="#">
                                        <i class="uil uil-facebook-f"></i>
                                    </a>
                                </li>
                                <li class="w-8 h-8 leading-loose text-center text-gray-200 transition-all duration-300 border rounded-full cursor-pointer border-gray-200/50 hover:text-gray-50 group-data-[theme-color=violet]:hover:bg-violet-500 group-data-[theme-color=sky]:hover:bg-sky-500 group-data-[theme-color=red]:hover:bg-red-500 group-data-[theme-color=green]:hover:bg-green-500 group-data-[theme-color=pink]:hover:bg-pink-500 group-data-[theme-color=blue]:hover:bg-blue-500 hover:border-transparent">
                                    <a href="#">
                                        <i class="uil uil-linkedin-alt"></i>
                                    </a>
                                </li>
                                <li class="w-8 h-8 leading-loose text-center text-gray-200 transition-all duration-300 border rounded-full cursor-pointer border-gray-200/50 hover:text-gray-50 group-data-[theme-color=violet]:hover:bg-violet-500 group-data-[theme-color=sky]:hover:bg-sky-500 group-data-[theme-color=red]:hover:bg-red-500 group-data-[theme-color=green]:hover:bg-green-500 group-data-[theme-color=pink]:hover:bg-pink-500 group-data-[theme-color=blue]:hover:bg-blue-500 hover:border-transparent">
                                    <a href="#">
                                        <i class="uil uil-google"></i>
                                    </a>
                                </li>
                                <li class="w-8 h-8 leading-loose text-center text-gray-200 transition-all duration-300 border rounded-full cursor-pointer border-gray-200/50 hover:text-gray-50 group-data-[theme-color=violet]:hover:bg-violet-500 group-data-[theme-color=sky]:hover:bg-sky-500 group-data-[theme-color=red]:hover:bg-red-500 group-data-[theme-color=green]:hover:bg-green-500 group-data-[theme-color=pink]:hover:bg-pink-500 group-data-[theme-color=blue]:hover:bg-blue-500 hover:border-transparent">
                                    <a href="#">
                                        <i class="uil uil-twitter"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 mt-8 md:col-span-6 xl:col-span-2 md:mt-0">
                    <p class="mb-6 text-white text-16">For Jobs</p>
                    <ul class="space-y-4">
                        <li class="text-sm transition-all duration-500 ease-in-out text-white/50 hover:text-gray-50 hover:text-base dark:text-gray-300 dark:hover:text-gray-50">
                            <router-link to="/web3-remote-jobs/"><i class="mdi mdi-chevron-right"></i> Remote Web3 Jobs</router-link>
                        </li>
                        <li class="text-sm transition-all duration-500 ease-in-out text-white/50 hover:text-gray-50 hover:text-base dark:text-gray-300 dark:hover:text-gray-50">
                            <router-link to="/web3-location-usa/"><i class="mdi mdi-chevron-right"></i> Web3 USA Jobs</router-link>
                        </li>
                        <li class="text-sm transition-all duration-500 ease-in-out text-white/50 hover:text-gray-50 hover:text-base dark:text-gray-300 dark:hover:text-gray-50">
                            <router-link to="/web3-tech-jobs/"><i class="mdi mdi-chevron-right"></i> Web3 Jobs</router-link>
                        </li>
                        <li class="text-sm transition-all duration-500 ease-in-out text-white/50 hover:text-gray-50 hover:text-base dark:text-gray-300 dark:hover:text-gray-50">
                            <router-link to="/web3-non-tech-jobs/"><i class="mdi mdi-chevron-right"></i> Non Tech Web3 Jobs</router-link>
                        </li>
                        <li class="text-sm transition-all duration-500 ease-in-out text-white/50 hover:text-gray-50 hover:text-base dark:text-gray-300 dark:hover:text-gray-50">
                            <router-link to="/web3-internship-jobs/"><i class="mdi mdi-chevron-right"></i> Internship Web3 Jobs</router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-span-12 mt-8 md:col-span-6 xl:col-span-2 md:mt-0">
                    <p class="mb-6 text-white text-16">By Skills</p>
                    <ul class="space-y-4">
                        <li class="text-sm transition-all duration-500 ease-in-out text-white/50 hover:text-gray-50 hover:text-base dark:text-gray-300 dark:hover:text-gray-50">
                            <router-link to="/web3-jobs/">
                                <i class="mdi mdi-chevron-right"></i> Browse Skills
                            </router-link>
                        </li>
                        <li class="text-sm transition-all duration-500 ease-in-out text-white/50 hover:text-gray-50 hover:text-base dark:text-gray-300 dark:hover:text-gray-50">
                            <router-link to="/web3-jobs/solidity/">
                                <i class="mdi mdi-chevron-right"></i> Solidity jobs
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-span-12 mt-8 md:col-span-6 xl:col-span-2 md:mt-0">
                    <p class="mb-6 text-white text-16">Resources</p>
                    <ul class="space-y-4">
                        <li class="text-sm transition-all duration-500 ease-in-out text-white/50 hover:text-gray-50 hover:text-base dark:text-gray-300 dark:hover:text-gray-50">
                            <router-link to="/articles/"><i class="mdi mdi-chevron-right"></i> Articles</router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-span-12 mt-8 md:col-span-6 xl:col-span-2 md:mt-0">
                    <p class="mb-6 text-white text-16">Support</p>
                    <ul class="space-y-4">
                        <li class="text-sm transition-all duration-500 ease-in-out text-white/50 hover:text-gray-50 hover:text-base dark:text-gray-300 dark:hover:text-gray-50">
                            <router-link to="/contact-us/"><i class="mdi mdi-chevron-right"></i> Contact Us</router-link>
                        </li>
                        <li class="text-sm transition-all duration-500 ease-in-out text-white/50 hover:text-gray-50 hover:text-base dark:text-gray-300 dark:hover:text-gray-50">
                            <router-link to="/policies/"><i class="mdi mdi-chevron-right"></i> Policies</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="py-6 border-t bg-zinc-800 border-gray-100/10 dark:bg-neutral-900">
        <div class="container mx-auto">
            <div class="text-center">
                <p class="mb-0 text-center text-white/50">
                    © TheJobTap
                </p>
            </div>
        </div>
    </section>
</footer>


<!-- <footer data-test-key="footer" class="footer">
    <router-link class="_footerLink" to="/contact-us/">Contact</router-link> | 
    <router-link class="_footerLink" to="/policies/">Policies</router-link>
    <br/>
    <br/>
    <a class="_footerLink" href="/web3-remote-jobs/">Remote Web3 Jobs</a> |
    <a class="_footerLink" href="/web3-location-usa/">Web3 Jobs in the USA</a> |
    <a class="_footerLink" href="/web3-tech-jobs/">Tech Web3 Jobs</a> |
    <a class="_footerLink" href="/web3-non-tech-jobs/">Non Tech Web3 Jobs</a> |
    <a class="_footerLink" href="/web3-internship-jobs/">Internship Web3 Jobs</a>
    <br/>
    <br/>
    <p>Popular searches</p>
    <router-link class="_footerLink" to="/web3-remote-jobs/">Developer Web3 Jobs</router-link> |
    <router-link class="_footerLink" to="/web3-marketing-jobs/">Marketing Web3 Jobs</router-link> |
    <router-link class="_footerLink" to="/web3-legal-jobs/">Legal Web3 Jobs</router-link> |
    <router-link class="_footerLink" to="/web3-finance-jobs/">Finance Web3 Jobs</router-link>
</footer> -->

</template>

<script src="../../src/assets/js/pages/nav&tabs.js"></script>
