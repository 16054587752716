<template>
<div class="page-content">

  <section class="pt-28 lg:pt-44 pb-28 group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 dark:bg-neutral-900 bg-[url('../images/home/page-title.png')] bg-center bg-cover relative" >
      <div class="container mx-auto">
          <div class="grid">
              <div class="col-span-12">
                  <div class="text-center text-white">
                      <h3 class="mb-4 text-[26px]">{{ pageContent?.Title }}</h3>
                      <div class="page-next">
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <img src="@/assets/images/about/shape.png" alt="" class="absolute block bg-cover -bottom-0 dark:hidden">
    <img src="@/assets/images/about/shape-dark.png" alt="" class="absolute hidden bg-cover -bottom-0 dark:block">
  </section>

  <section class="py-12">
        <div class="container mx-auto">
          <div v-if="pageContentFound == null">
              <img class="_imgLoader" src="@/assets/images/loading.gif"/>
            </div> 
            <div v-else-if="pageContentFound == false">
        <p>not found</p>
    </div>
    <div v-else class="grid grid-cols-12 gap-y-10 lg:gap-10 lg:items-center">
     <div class="col-span-12 text-center">
            <ul class="flex flex-wrap items-center mt-3 mb-0 text-gray-500 ">
                        <li v-if="pageContentFound == true">
                            <div class="flex items-center">
                                <div class="shrink-0">
                                    <!-- <img :src=article.MainImage alt="" class="w-12 h-12 rounded-full"> -->
                                    <img class="w-30 h-24 mx-auto" :src=pageContent.MainImage alt="">
                                </div>
                            </div>
                        </li>
                        <li class="ltr:ml-3 rtl:mr-3">
                            <div class="flex items-center">
                                <div class="ltr:ml-2 rtl:mr-2">
                                    <h2 class="mb-0 dark:text-gray-300">{{ pageContent.Title }}</h2>

                                </div>
                            </div>
                        </li>
                    </ul>
      </div>      

      <div class="col-span-12 lg:col-span-8">
                    <div class="border rounded border-gray-100/50 dark:border-neutral-600">
                        <div class="p-5 border-b border-gray-100/50 dark:border-neutral-600 "  style="min-height: 250px;">
                        <div>
                            <!-- LandingPage: Content -->
                            <WysiwygViewer class="text-gray-900 dark:text-gray-50" :viewerText="this.pageContent.Content" />
                        </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-span-12 lg:col-span-4">
                    <div class="border rounded border-gray-100/50 dark:border-neutral-600">
                        <div class="p-5 border-b border-gray-100/50 dark:border-neutral-600"  style="min-height: 250px;">
                            <div class="text-center">
                             
                            <p class="text-gray-500 dark:text-gray-300 text-center" >Combine your skills and search jobs like this.</p>
                                                <multiselect 
                                                    v-model="this.search.tags" 
                                                    :options="options" 
                                                    :searchable="false" 
                                                    :multiple="true" 
                                                    :close-on-select="false" 
                                                    :clear-on-select="true"              
                                                    :preserve-search="false" 
                                                    placeholder="Select skills" 
                                                    :select-label="'Tap to select'"
                                                    :deselect-label="'Tap to remove'"
                                                    label="" 
                                                    track-by="" 
                                                    :preselect-first="false">
                                                    <template #selection="{ values, isOpen }">
                                                        <span class="multiselect__single"
                                                            v-if="values.length"
                                                            v-show="!isOpen">
                                                            {{ values.length }} tags selected: 
                                                            <ul class="flex flex-wrap gap-2">
                                                                <li 
                                                                    class="border p-[6px] border-gray-100/50 rounded group/joblist dark:border-gray-100/20" 
                                                                    v-bind:key="tag" 
                                                                    v-for="tag in this.search.tags" >
                                                                    <div class="flex items-center">
                                                                            <a href="javascript:void(0)" 
                                                                            class="text-gray-900 dark:text-gray-50">
                                                                                <h6 class="mb-0 transition-all duration-300 fs-14 group-data-[theme-color=violet]:group-hover/joblist:text-violet-500 group-data-[theme-color=sky]:group-hover/joblist:text-sky-500 group-data-[theme-color=red]:group-hover/joblist:text-red-500 group-data-[theme-color=green]:group-hover/joblist:text-green-500 group-data-[theme-color=pink]:group-hover/joblist:text-pink-500 group-data-[theme-color=blue]:group-hover/joblist:text-blue-500">
                                                                                {{ tag }}
                                                                                </h6>
                                                                            </a>
                                                                        </div>

                                                                </li>
                                                            </ul>
                                                        </span>
                                                    </template>
                                                </multiselect>
                                                <!-- Search: Remote selection -->
                                                <div class="checkbox-wrapper-64">
                                                  <label class="switch">
                                                    <input type="checkbox" 
                                                      v-on:click="this.search.remoteOnly = !this.search.remoteOnly"
                                                      v-model="this.search.isRemote">
                                                    <span class="slider"></span>
                                                  </label>
                                                  <span class="relative text-gray-900 text-16 dark:text-gray-50">Remote only</span>
                                                </div>
                                                <br>
                                                <button @click.stop="searchClickHandle" class="text-white border-transparent group-data-[theme-color=violet]:bg-violet-500 group-data-[theme-color=sky]:bg-sky-500 group-data-[theme-color=red]:bg-red-500 group-data-[theme-color=green]:bg-green-500 group-data-[theme-color=pink]:bg-pink-500 group-data-[theme-color=blue]:bg-blue-500 btn hover:-translate-y-2">Search <i class="align-middle uil uil-rocket ms-1"></i></button>
         
                            </div>
                        </div>
                    </div>
                </div>




            </div>
        </div>
        
    </section>


  </div>

</template>
<script>

import { siteAPI } from "@/api/site";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import EmailSubscriptionForm from "@/components/Forms/EmailSubscriptionForm.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import WysiwygViewer from '@/components/WysiwygViewer.vue'
import { jobListingCategoryAPI } from "@/api/jobListingCategory";
import Multiselect from 'vue-multiselect'

const pageStatus = useStatus();
const log = useErrorLogger();

  export default{
    name: "LandingPage",
  components : {
    EmailSubscriptionForm, FeedbackNotification, WysiwygViewer, Multiselect
  }, 
  data() {
    return {
      notification : {
        text : "",
        level : "",
      },
      search: {
        tags: [],
        remoteOnly: true
      },
      pageContent : null,
      pageContentFound: null,
      options: []
    }
  },
  created()
  {
    this.getLandingPage();
    this.getJobListingCategories();
  },
  mounted() {
    
  },
  methods: {
  async getLandingPage() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await siteAPI.getLandingPage(this.$route.name);
          if (result == null)
          {
            this.pageContentFound = false;
          }
          else
          {
            this.pageContentFound = true;
            this.pageContent = result.data;
            document.title = this.pageContent.Title + ' - TheJobTap.com';
          }
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }
    },
    async getJobListingCategories() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingCategoryAPI.getCategories();
          result.forEach((element) => { 
            this.options.push(element.Name);
          })
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }
    },

}
};
  </script>